import React, { MouseEvent } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import CommonButton from '../Common/CommonButton';
import ModalCustom from '../Common/ModalCustom';

type Props = {
  visible: boolean;
  onChangeVisible: (visible: boolean) => void;
};

const ClaimCreditsPopup = ({ visible, onChangeVisible }: Props) => {
  const { t } = useTranslation();

  const onConfirm = (e: MouseEvent): void => {
    e.preventDefault();
    window.location.replace('https://t.me/evohackers');
  };

  return (
    <ModalCustom
      title={t('label:popup.claim_free_credits')}
      open={visible}
      onOk={onConfirm}
      onCancel={() => onChangeVisible(false)}
      wrapClassName="popup-custom_two"
    >
      <Col>
        <Row className="flex-col flex-sm-row flex-wrap" gutter={14}>
          <span className="label text-white">{t('label:cfc.description')}</span>
        </Row>
        <Row className="flex-col flex-sm-row flex-wrap popup-content" gutter={14}>
          <Col>
            <CommonButton onClick={onConfirm} className="text-white fw-bold w-20 my-2">
              {t('label:btn.confirm')}
            </CommonButton>
          </Col>
        </Row>
      </Col>
    </ModalCustom>
  );
};

export default ClaimCreditsPopup;
