import { Col } from 'antd';
import React, { useEffect, useState } from 'react';
import StarIcon from 'src/assets/images/star.svg';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import CommonButton from 'src/components/Common/CommonButton';
import { Link } from 'react-router-dom';
import { getProviders } from 'src/stores/app/app.service';
// import bannerJson from 'src/pages/Admin/BannerSetting/banner.json';
// import { PositionBanner } from '../Admin/BannerSetting';
import { t } from 'i18next';
import { LobbyCard } from './type';

const starIconSize = 15;
const menuIconSize = 20;

interface Props {
  onMenuSelected: (data: any) => void;
  menuSelected: any;
}

const SidebarDesktopDashboard = (props: Props) => {
  // const bannerSideDesktop = bannerJson.banners.find(
  //   (item) => item.position === PositionBanner.DASHBOARD_SIDE_LEFT_DESKTOP,
  // );

  const { menuSelected, onMenuSelected } = props;
  const [listProviders, setListProviders] = useState([]);
  const selectedProvider = LobbyCard.find((p) => p.key === menuSelected);

  useEffect(() => {
    getProviders()
      .then((res: any) => {
        setListProviders(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkMenu = (menuCode: string) => {
    const find = listProviders.find((provider: any) => provider.provider === menuCode && provider.show);
    return find ? true : false;
  };

  // const onClickBanner = (url: string) => () => {
  //   window.open(url, '_blank');
  // };

  return (
    <Col className="left-dash dashboard-sidebar-D d-none d-md-inline-flex align-items-stretch">
      <div className="w-100 h-100">
        <div className="left-top w-75 m-auto fw-bold mt-4 mb-5">
          <div className="row">
            <div className="col-1">
              <img width={starIconSize} height={starIconSize} src={StarIcon} alt="StarIcon" />
            </div>
            <div className="col left-title">{t('label:title.trusted').toUpperCase()}</div>
          </div>
          <div className="mt-3 mb-1">
            <img className="img-fluid" src={selectedProvider?.profile} alt={selectedProvider?.title} />
          </div>
          <div className="text-center d-flex flex-column">
            <div className="fs-6" style={{ color: '#ef9a23' }}>
              {selectedProvider?.title.toUpperCase()}
            </div>
            <div className="fw-normal" style={{ color: '#e3d2ff' }}>
              {t('label:title.baccarat_formula').toUpperCase()}
            </div>
          </div>
        </div>
        <div className="d-flex flex-column" style={{ color: '#e2d1fe' }}>
          {LobbyCard.map(function (item: any) {
            if (checkMenu(item.key)) {
              return (
                <div
                  key={item.key}
                  className={`left-dash-menu-item cursor-pointer ${menuSelected === item.key ? 'item-selected' : ''}`}
                  onClick={() => onMenuSelected(item.key)}
                >
                  <div className="w-75 m-auto d-flex flex-row py-2">
                    <div className="pe-2">
                      <img width={menuIconSize} src={item.icon} alt={item.title} />
                    </div>
                    <div>{item.title}</div>
                    <div className="ms-auto d-flex align-items-center">
                      <RightOutlined style={{ color: '#908d96' }} />
                    </div>
                  </div>
                </div>
              );
            }

            return undefined;
          })}
        </div>
        <div className="w-75 m-auto mt-3">
          <Link to="/lobby">
            <CommonButton className="w-100 fw-bold text-light">
              <LeftOutlined className="fw-normal me-2" style={{ color: '#908d96' }} />
              <span>{t('label:lobby.header')}</span>
            </CommonButton>
          </Link>
        </div>
        {/* {bannerSideDesktop && bannerSideDesktop.url && (
          <div className="banner mt-md-4 mb-4 w-75 mx-auto" onClick={onClickBanner(bannerSideDesktop.url)}>
            <img className="banner-image " src={bannerSideDesktop.image} alt="banner" />
          </div>
        )} */}
      </div>
    </Col>
  );
};

export default SidebarDesktopDashboard;
