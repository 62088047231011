import AeCard from '../../assets/images/ae-card.png';
import AgCard from '../../assets/images/ag-card.jpg';
import EvolutionCard from '../../assets/images/evolution-card.jpg';
import GpCard from '../../assets/images/gp-card.jpg';
import SaCard from '../../assets/images/sa-card.jpg';
import EbCard from '../../assets/images/EB-card.png';
import BgCard from '../../assets/images/bg-card.jpg';
import DgCard from '../../assets/images/dg-card.png';

import AgProfile from '../../assets/images/profile-asiagaming.png';
import EvolutionProfile from '../../assets/images/profile-evo.png';
import SaProfile from '../../assets/images/profile-sagaming.png';
import EbetProfile from '../../assets/images/profile-ebet.png';
import BgProfile from '../../assets/images/profile-bg.png';
import AESProfile from '../../assets/images/profile-aes.png';
import DgProfile from '../../assets/images/profile-dg.png';

import AeIcon from 'src/assets/images/ae.svg';
import AgIcon from 'src/assets/images/ag.svg';
import EvolutionIcon from 'src/assets/images/evolution.svg';
import EbetIcon from 'src/assets/images/ebet-icon.png';
import GamePlayIcon from 'src/assets/images/gp.svg';
import SaIcon from 'src/assets/images/sa.svg';
import BgIcon from 'src/assets/images/bg-icon.png';
import DgIcon from 'src/assets/images/dg-icon.png';

export enum MenuType {
  EVOLUTION = 'EVO',
  AE_CASINO = 'AES',
  ASIA_GAMING = 'AG',
  SA_GAMING = 'SA',
  GAMEPLAY = 'GP',
  EBET = 'EBET',
  BG = 'BG',
  DG = 'DG',
}

const { EVOLUTION, AE_CASINO, ASIA_GAMING, SA_GAMING, GAMEPLAY, EBET, BG, DG } = MenuType;

export const LobbyCard = [
  {
    key: EVOLUTION,
    icon: EvolutionIcon,
    title: 'Evolution gaming',
    profile: EvolutionProfile,
    code: EVOLUTION,
    image: EvolutionCard,
  },
  {
    key: ASIA_GAMING,
    icon: AgIcon,
    title: 'Asia Gaming',
    profile: AgProfile,
    code: ASIA_GAMING,
    image: AgCard,
  },
  {
    key: SA_GAMING,
    icon: SaIcon,
    title: 'SA Gaming',
    profile: SaProfile,
    code: SA_GAMING,
    image: SaCard,
  },
  {
    key: GAMEPLAY,
    icon: GamePlayIcon,
    title: 'Gameplay',
    profile: '',
    code: GAMEPLAY,
    image: GpCard,
  },
  {
    key: EBET,
    icon: EbetIcon,
    title: 'EBET',
    profile: EbetProfile,
    code: EBET,
    image: EbCard,
  },
  {
    key: BG,
    icon: BgIcon,
    title: 'Big Gaming',
    profile: BgProfile,
    code: BG,
    image: BgCard,
  },
  {
    key: AE_CASINO,
    icon: AeIcon,
    title: 'Sexy Bacarrat',
    profile: AESProfile,
    code: AE_CASINO,
    image: AeCard,
  },
  {
    key: DG,
    icon: DgIcon,
    title: 'Dream Gaming',
    profile: DgProfile,
    code: DG,
    image: DgCard,
  },
];

export interface DeActiveTableType {
  table: any;
  predictions: any;
}
