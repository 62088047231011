import { useTypedSelector } from './useTypedSelector';
import _ from 'lodash';
import { UserEntity } from 'src/stores/auth/model';

export const useCurrentUser = (): UserEntity | undefined | null => {
  return useTypedSelector((state) => {
    const result = _.cloneDeep(state.auth.user);
    if (result) {
      return result;
    }
    return null;
  });
};
