import { createSlice } from '@reduxjs/toolkit';
import { UserEntity, WallerEntity } from './model';

export enum UserRole {
  ADMIN = 'ROLE_ADMIN',
  USER = 'ROLE_USER',
}

type AuthState = {
  user: UserEntity | undefined | null;
  wallet: WallerEntity | undefined | null;
  selectedRoleUser: UserRole;
};

export type UserPoint = {
  in: number;
  out: number;
};

const initialState: AuthState = {
  user: undefined,
  wallet: undefined,
  selectedRoleUser: UserRole.USER,
};
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authActionSlice: (state, action) => {
      state.user = action.payload;
    },
    walletActionSlice: (state, action) => {
      state.wallet = action.payload;
    },
    logoutActionSlice: (state) => {
      state.user = null;
    },
    selectedRoleUserSlice: (state, action) => {
      state.selectedRoleUser = action.payload;
    },
  },
});

export const { authActionSlice, logoutActionSlice, walletActionSlice, selectedRoleUserSlice } = authSlice.actions;
