import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, useLocation } from 'react-router-dom';
import { useAuthenticated } from 'src/hooks/useAuthenticated';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { useTypedDispatch } from 'src/hooks/useTypedDispatch';
import { selectUserRoleAction } from 'src/stores/auth/auth.actions';
import { UserRole } from 'src/stores/auth/auth.slice';
import { AppRoutes } from './app.routes';
import { useTranslation } from 'react-i18next';
import { countryCodes, LanguageType } from 'src/helpers/constanst';

type Props = {
  layout?: React.ComponentType<any>;
  isPrivate?: boolean;
  redirect?: string;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  role?: string[];
} & RouteProps;

export const RouteLayout: React.FC<Props> = ({
  layout: Layout,
  component: Component,
  isPrivate,
  role,
  redirect,
  path,
  ...props
}) => {
  const dispatch = useTypedDispatch();
  const isAuthenticated = useAuthenticated();
  const user = useCurrentUser();

  const location = useLocation();
  const { i18n } = useTranslation();

  // Extract the language and country from the query parameters
  const searchParams = new URLSearchParams(location?.search);
  const langCountry = searchParams?.get('lang')?.toLowerCase()?.split('-');
  const [lang, country] = langCountry || ['en', null];

  // Update the URL with the new language and country
  if (!isAuthenticated) {
    const storedLanguage = localStorage.getItem('language');
    const storedPhoneCode = localStorage.getItem('phoneCode');

    if (!storedLanguage && lang) {
      const languageSupported = Object.values(LanguageType).includes(lang as LanguageType);
      const initLang = languageSupported ? lang : 'en';
      localStorage.setItem('language', initLang);
      i18n.changeLanguage(initLang);
    }

    if (!storedPhoneCode && country) {
      const convertedCountry = countryCodes.find((item) => item.country.toLowerCase() === country?.toLowerCase());
      if (convertedCountry) {
        localStorage.setItem('phoneCode', convertedCountry?.code);
      }
    }
  }

  if (role?.length === 1 && role[0] === UserRole.ADMIN) {
    dispatch(selectUserRoleAction(UserRole.ADMIN));
  } else {
    dispatch(selectUserRoleAction(UserRole.USER));
  }

  if (role && role.length) {
    const authorities = role.some((item) => {
      return user?.authorities?.includes(item);
    });
    if (!authorities) {
      return <Redirect to={AppRoutes.notFound} />;
    }
  }

  if (!isAuthenticated && isPrivate) return <Redirect to={redirect ?? AppRoutes.login} />;
  if (path === AppRoutes.login && isAuthenticated) {
    return <Redirect to={redirect ?? AppRoutes.lobby} />;
  }
  return (
    <Route
      {...props}
      render={(ownProps) => {
        if (Layout)
          return (
            <Layout>
              <Component {...ownProps} />
            </Layout>
          );
        return <Component {...ownProps} />;
      }}
    />
  );
};

export default RouteLayout;

RouteLayout.defaultProps = {
  isPrivate: true,
};
