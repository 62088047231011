/* eslint-disable prettier/prettier */
import React from 'react';
import { Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { countryCodes } from 'src/helpers/constanst';
const { Option } = Select;

type hideCountryProps = {
  hideCountry?: string[];
};

export type CountryProps<ValueType> = SelectProps<ValueType> & hideCountryProps;

export const countryVisible = (hideCountry?: string[]) => {
  return hideCountry ? countryCodes.filter((item) => !hideCountry.includes(item.country)) : countryCodes;
};

function PhoneCodeList<ValueType>(props: CountryProps<ValueType>) {
  const { t } = useTranslation();
  const { hideCountry } = props || [];
  const countryCode = countryVisible(hideCountry);

  return (
    <Select
      {...props}
      className="form-Select form-input-style py-1 select-padding-sm"
      placeholder={t('label:auth.code_label')}
    >
      {countryCode.map((item) => {
        return (
          <Option className="option-padding-sm" value={item.code} key={item.code}>
            <img className="me-1" width={15} height={15} src={item.flag} alt="" />+{item.code}
          </Option>
        );
      })}
    </Select>
  );
}

export default PhoneCodeList;
