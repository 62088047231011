import { useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { logoutServices } from 'src/stores/auth/auth.service';
import { useAuthenticated } from './useAuthenticated';
import { RESET_DEBOUNCE_TIME, SCAN_TIME_INTERVAL, UN_ACTIVE_LIMIT, UN_ACTIVE_TIMER_KEY } from 'src/helpers/constanst';
import { getUnixTime, add } from 'date-fns';
import { clearAuthLocalData } from 'src/helpers/auth';

export default function useUnActiveSystem() {
  const isAuthenticated = useAuthenticated();

  const unActiveTimerRef = useRef<any>(null);
  const latestScannedTimeRef = useRef<number>(0);
  const timeoutInSecondsRef = useRef<number>(0);
  const nowRef = useRef<number>(getUnixTime(new Date()));

  const setUnActiveTimer = () => {
    if (!unActiveTimerRef.current) {
      if (!window.localStorage.getItem(UN_ACTIVE_TIMER_KEY)) {
        // set initial timeout in seconds to local storage
        window.localStorage.setItem(
          UN_ACTIVE_TIMER_KEY,
          String(getUnixTime(add(new Date(), { seconds: UN_ACTIVE_LIMIT }))),
        );
      }

      // start interval timer
      unActiveTimerRef.current = setInterval(async () => {
        timeoutInSecondsRef.current = Number(window.localStorage.getItem(UN_ACTIVE_TIMER_KEY));
        latestScannedTimeRef.current += SCAN_TIME_INTERVAL;

        if (nowRef.current + latestScannedTimeRef.current >= timeoutInSecondsRef.current) {
          await logoutServices();
          clearAuthLocalData();
          latestScannedTimeRef.current = 0;
          window.location.href = '/login';
        }
      }, SCAN_TIME_INTERVAL * 1000);
    }
  };

  const resetUnActiveTimer = () => {
    latestScannedTimeRef.current = 0;
    nowRef.current = getUnixTime(new Date());

    window.localStorage.setItem(
      UN_ACTIVE_TIMER_KEY,
      String(getUnixTime(add(new Date(), { seconds: UN_ACTIVE_LIMIT }))),
    );
  };

  const resetUnActiveTimerDebounce = debounce(resetUnActiveTimer, RESET_DEBOUNCE_TIME);

  useEffect(() => {
    if (!isAuthenticated) return;

    const onMouseEventListener = () => {
      document.addEventListener('mousemove', resetUnActiveTimerDebounce);
      document.addEventListener('click', resetUnActiveTimerDebounce);
    };

    const removeMouseEventListener = () => {
      document.removeEventListener('mousemove', resetUnActiveTimerDebounce);
      document.removeEventListener('click', resetUnActiveTimerDebounce);
    };

    setUnActiveTimer();
    onMouseEventListener();

    return () => {
      removeMouseEventListener();
      clearInterval(unActiveTimerRef.current);
      unActiveTimerRef.current = null;
      window.localStorage.removeItem(UN_ACTIVE_TIMER_KEY);
    };
  }, [isAuthenticated, resetUnActiveTimerDebounce]);
}
