import { Col, ModalProps, notification, Row } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommonButton from 'src/components/Common/CommonButton';
import ModalCustom from 'src/components/Common/ModalCustom';
import { resetPasswordServices } from 'src/stores/app/app.service';

const ResetPasswordPopup = (
  props: { user?: any; onCancelModal?: () => void; resetSucces?: (val: any) => void } & ModalProps,
) => {
  const { t } = useTranslation();
  const [loadingReset, setLoadingReset] = useState<boolean>(false);
  const handlerCancel = () => {
    props.onCancelModal && props.onCancelModal();
  };

  const hanlderResetPassword = async () => {
    if (props.user) {
      setLoadingReset(true);
      await resetPasswordServices(props.user)
        .then((res) => {
          props.resetSucces && props.resetSucces(res.data);
          handlerCancel();
        })
        .catch(() => {
          notification.error({
            message: t('label:message.error'),
            description: t('label:message.something_when_wrong'),
          });
        });
      setLoadingReset(false);
    }
  };

  return (
    <ModalCustom title="" wrapClassName="popup-custom_one" closeIcon={null} {...props} onCancel={handlerCancel}>
      <Col className="p-2 p-md-3 mt-4">
        <Col className="text-white d-flex flex-column align-items-center pb-3">
          <span className="text-center">
            {t('label:auth.confirmResetPasswordLabel')} {`${props.user?.login}'s`}{' '}
            {t('label:auth.password_label').toLocaleLowerCase()}?
          </span>
        </Col>
        <Row gutter={18} className="flex-wrap">
          <Col span={12}>
            <CommonButton
              disabled={loadingReset}
              className="btn-setting w-100 mx-auto py-2"
              onClick={hanlderResetPassword}
            >
              {t('label:btn.confirm')}
            </CommonButton>
          </Col>
          <Col span={12}>
            <CommonButton className="btn-edit w-100 mx-auto py-2" onClick={handlerCancel}>
              {t('label:btn.cancel')}
            </CommonButton>
          </Col>
        </Row>
      </Col>
    </ModalCustom>
  );
};

export default ResetPasswordPopup;
