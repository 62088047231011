import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.min.css';
import './App.css';
import '../components/Common/common.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { memo } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Wrapper, WrapperLobby } from 'src/components/Common/Wrapper';
import AdminPage from 'src/pages/Admin';
import BannerSetting from 'src/pages/Admin/BannerSetting';
import ExternalLogin from 'src/pages/Admin/ExternalLogin';
import UserDetail from 'src/pages/Admin/InforMember/InforMember';
import WalletEvent from 'src/pages/Admin/WalletEvent/WalletEvent';
import ExternalRegister from 'src/pages/ExternalRegister';
import Dashboard from '../pages/Dashboard';
import Lobby from '../pages/Lobby/Lobby';
import Login from '../pages/Login/Login';
import PageNotFound from '../pages/PageNotFound';
import Register from '../pages/Register/Register';
import ResetPassword from '../pages/Reset/ResetPassword';
import { AppRoutes } from './app.routes';
import RouteLayout from './RouteLayout';

export const RootRouter = memo(() => {
  return (
    // @ts-ignore
    <BrowserRouter>
      <Switch>
        <RouteLayout role={['ROLE_ADMIN']} path={AppRoutes.userDetail} exact component={UserDetail} layout={Wrapper} />
        <RouteLayout
          role={['ROLE_ADMIN']}
          path={AppRoutes.walletEvent}
          exact
          component={WalletEvent}
          layout={Wrapper}
        />
        <RouteLayout isPrivate={false} path={AppRoutes.login} exact component={Login} layout={Wrapper} />
        <RouteLayout isPrivate={false} path={AppRoutes.register} exact component={Register} layout={Wrapper} />
        <RouteLayout isPrivate={false} path={AppRoutes.reset} exact component={ResetPassword} layout={Wrapper} />
        <RouteLayout
          isPrivate={false}
          path={AppRoutes.externalRegister}
          exact
          component={ExternalRegister}
          layout={Wrapper}
        />
        <RouteLayout isPrivate={false} path={AppRoutes.loginExternal} exact component={ExternalLogin} />
        <RouteLayout role={['ROLE_ADMIN']} path={AppRoutes.admin} exact component={AdminPage} layout={Wrapper} />
        <RouteLayout path={AppRoutes.bannerSetting} exact component={BannerSetting} layout={Wrapper} />
        <RouteLayout path={AppRoutes.dashboard} exact component={Dashboard} layout={Wrapper} />
        <RouteLayout path={AppRoutes.lobby} exact component={Lobby} layout={WrapperLobby} />
        <Route path={AppRoutes.notFound} component={PageNotFound} exact />
        <Redirect path="/" to={AppRoutes.login} />
      </Switch>
    </BrowserRouter>
  );
});
