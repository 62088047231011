import axios, { AxiosRequestConfig } from 'axios';
import { clearAuthLocalData } from 'src/helpers/auth';
import { API_URL } from 'src/helpers/constanst';
const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
  },
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (err) => {
    const codeRes = err.response.data.code;
    const httpResStatus = err.response.status;

    if (httpResStatus === 401 || [4013, 4011].includes(codeRes)) {
      clearAuthLocalData();
      window.location.href = `/login?errCode=${codeRes}`;
    }

    return Promise.reject(err);
  },
);

const post = (url: string, data?: any, config: AxiosRequestConfig = {}) => {
  return axiosInstance.post(url, data, config);
};
const get = (url: string, config: AxiosRequestConfig = {}) => {
  return axiosInstance.get(url, config);
};

const put = (url: string, data: any, config: AxiosRequestConfig = {}) => {
  return axiosInstance.put(url, data, config);
};

const patch = (url: string, data?: any, config: AxiosRequestConfig = {}) => {
  return axiosInstance.patch(url, data, config);
};

const del = (url: string, config: AxiosRequestConfig = {}) => {
  return axiosInstance.delete(url, config);
};

export function setConfigAxios(accessToken: string | null | undefined) {
  axiosInstance.defaults.headers.common = {};
  axiosInstance.defaults.timeout = 12000;
  if (accessToken) {
    axiosInstance.defaults.headers.common['authorization'] = `Bearer ${accessToken}`;
  }
}

const apiServices = {
  post,
  get,
  put,
  patch,
  delete: del,
};

export { apiServices };
