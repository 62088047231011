import { getLineLoginLink } from 'src/stores/app/app.service';

export const lineLoginHandle = async () => {
  const loginRes = await getLineLoginLink();
  if (loginRes.status === 200 && loginRes.data?.LINE) {
    window.open(loginRes.data.LINE, '_self');
  }

  return;
};

export const OpenWindowWithPost = (url: string, name: string, params: any) => {
  const form = document.createElement('form');
  form.setAttribute('method', 'post');
  form.setAttribute('action', url);
  form.setAttribute('target', name);

  for (const param of params) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = param.name;
    input.value = param.value;
    form.appendChild(input);
  }

  document.body.appendChild(form);

  form.requestSubmit();

  document.body.removeChild(form);
};
