import { Dropdown, Menu } from 'antd';
import React from 'react';
import ProfileIcon from '../../assets/images/profile-icon.png';
import AdminIcon from '../../assets/images/admin-icon.png';
import PasswordIcon from '../../assets/images/password-icon.png';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { useCurrentUserRole } from 'src/hooks/useCurrentUserRole';
import { useTranslation } from 'react-i18next';
import { UserRole } from 'src/stores/auth/auth.slice';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from 'src/routes/app.routes';

interface Props {
  onShowChangePassword?: () => void;
  className?: string;
}

const DropdownUserManager = (props: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const currentRole = useCurrentUserRole();
  const user = useCurrentUser();
  const hanlderChangePage = () => {
    if (currentRole === UserRole.ADMIN) {
      history.push(AppRoutes.lobby);
    } else if (currentRole === UserRole.USER) {
      history.push(AppRoutes.admin);
    } else {
      history.push(AppRoutes.login);
    }
  };

  const handlerChangePassword = () => {
    props.onShowChangePassword && props.onShowChangePassword();
  };
  const menu = (
    <Menu className="dropdown-user-manager__overlay">
      <Menu.Item
        className={`${
          currentRole === UserRole.ADMIN && user?.authorities.includes(UserRole.USER) ? 'd-flex' : 'd-none'
        }`}
        onClick={hanlderChangePage}
      >
        <img className="me-2" width={20} height={20} src={ProfileIcon} alt="" />
        <span>{t('label:app.user')}</span>
      </Menu.Item>

      <Menu.Item
        className={`${
          currentRole === UserRole.USER && user?.authorities.includes(UserRole.ADMIN) ? 'd-flex' : 'd-none'
        }`}
        onClick={hanlderChangePage}
      >
        <img className="me-2" width={20} height={20} src={AdminIcon} alt="" />
        <span>{t('label:app.admin')}</span>
      </Menu.Item>
      <Menu.Item onClick={handlerChangePassword}>
        <img className="me-2" width={20} height={20} src={PasswordIcon} alt="" />
        <span>{t('label:auth.change_password')}</span>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown className={`dropdown-user-manager ${props.className || ''}`} overlay={menu}>
      <div>
        <img
          className="me-2"
          width={20}
          height={20}
          src={currentRole === UserRole.ADMIN ? AdminIcon : ProfileIcon}
          alt=""
        />
        <span className="label">
          {currentRole === UserRole.ADMIN
            ? t('label:app.admin')
            : currentRole === UserRole.USER
            ? t('label:app.user')
            : ''}{' '}
          - {user?.login}
        </span>
      </div>
    </Dropdown>
  );
};

export default DropdownUserManager;
