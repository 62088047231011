import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Collapse, Dropdown, Menu, Row } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCurrentUserRole } from 'src/hooks/useCurrentUserRole';
import { AppRoutes } from 'src/routes/app.routes';
import { UserRole } from 'src/stores/auth/auth.slice';

import BaseButtonPrimary from 'src/components/Common/Buttons/BaseButtonPrimary';
import { ArrayLanguage } from 'src/helpers/constanst';

import EngFlag from 'src/assets/flags/uk-flag.png';
import AdminIcon from 'src/assets/images/admin-icon.png';
import PasswordIcon from 'src/assets/images/password-icon.png';
import ProfileIcon from 'src/assets/images/profile-icon.png';

interface Props {
  user?: any;
  onLogout: () => void;
  onChangePassword: () => void;
}

const DropdownMenu = (props: Props) => {
  const { user, onChangePassword, onLogout } = props;
  const history = useHistory();
  const currentRole = useCurrentUserRole();
  const { t, i18n } = useTranslation();
  const [openDropdownMenu, setOpenDropdownMenu] = useState(false);

  const handleOpenChangeMenu = (flag: boolean) => {
    setOpenDropdownMenu(flag);
  };

  const onChangeLanguage = (language: string) => () => {
    localStorage.setItem('language', language);
    i18n.changeLanguage(language);
    setOpenDropdownMenu(false);
  };

  const hanlderChangePage = () => {
    if (currentRole === UserRole.ADMIN) {
      history.push(AppRoutes.lobby);
    } else if (currentRole === UserRole.USER) {
      history.push(AppRoutes.admin);
    } else {
      history.push(AppRoutes.login);
    }
  };

  const handlerChangePassword = () => {
    onChangePassword();
    setOpenDropdownMenu(false);
  };

  const getCurrentLanguageHeader = () => {
    const current = ArrayLanguage.find((item) => item.id === i18n.language);
    return (
      <Row className={`align-items-center my-1`}>
        <img className="me-2" width={18} height={18} src={current?.flag || EngFlag} alt={current?.label} />{' '}
        {current?.label}
      </Row>
    );
  };

  const getListLanguageNotSelect = () => {
    const listLanguage = ArrayLanguage.filter((item) => item.id !== i18n.language);
    return listLanguage.map((item) => {
      return (
        <Row key={item.id} className={`align-items-center`} onClick={onChangeLanguage(item.id)} style={{ padding: 16 }}>
          <img className="me-2" width={18} height={18} src={item.flag} alt="flag" /> {item.label}
        </Row>
      );
    });
  };

  const menu = (
    <Menu
      className="dropdow_menu_header d-md-none mt-3"
      items={[
        {
          key: 'dropdown_m',
          label: (
            <div>
              <Collapse
                className="collapse-language-header"
                onChange={() => {}}
                expandIconPosition="end"
                expandIcon={(props) => {
                  if (user?.authorities.includes(UserRole.ADMIN))
                    return props.isActive ? <DownOutlined /> : <UpOutlined />;
                }}
              >
                <Collapse.Panel
                  header={
                    <span className="d-flex align-items-center my-1 " style={{ width: 'max-content' }}>
                      <img
                        className="me-2"
                        width={20}
                        height={20}
                        src={currentRole === UserRole.ADMIN ? AdminIcon : ProfileIcon}
                        alt=""
                      />
                      {currentRole === UserRole.ADMIN
                        ? t('label:app.admin')
                        : currentRole === UserRole.USER
                        ? t('label:app.user')
                        : ''}{' '}
                      - {user?.login}
                    </span>
                  }
                  key="1"
                >
                  <span
                    className={`d-flex align-items-center ${
                      currentRole === UserRole.USER && user?.authorities.includes(UserRole.ADMIN) ? 'd-flex' : 'd-none'
                    } `}
                    style={{ width: 'max-content', padding: 16 }}
                    onClick={hanlderChangePage}
                  >
                    <img className="me-2" width={20} height={20} src={AdminIcon} alt="" />
                    {t('label:app.admin')} - {user?.login}
                  </span>

                  <span
                    className={`d-flex align-items-center ${
                      currentRole === UserRole.ADMIN && user?.authorities.includes(UserRole.USER) ? 'd-flex' : 'd-none'
                    }`}
                    style={{ width: 'max-content', padding: 16 }}
                    onClick={hanlderChangePage}
                  >
                    <img className="me-2" width={20} height={20} src={ProfileIcon} alt="" />
                    {t('label:app.user')} - {user?.login}
                  </span>
                </Collapse.Panel>
              </Collapse>
              <div className="mx-2 py-3 d-flex align-items-center" onClick={handlerChangePassword}>
                <img className="ms-1 me-2" width={25} height={25} src={PasswordIcon} alt="" />
                <span style={{ fontSize: 16 }}> {t('label:auth.change_password')}</span>
              </div>
              <Collapse
                className="collapse-language-header"
                onChange={() => {}}
                expandIconPosition="end"
                expandIcon={(props) => {
                  return props.isActive ? <DownOutlined /> : <UpOutlined />;
                }}
              >
                <Collapse.Panel header={getCurrentLanguageHeader()} key="1" className="mb-2">
                  {getListLanguageNotSelect()}
                </Collapse.Panel>
              </Collapse>
              <div className="logout-container">
                <BaseButtonPrimary
                  className="w-100 "
                  title={t('label:app.logout')}
                  prefixDefault="Logout"
                  onClick={() => {
                    onLogout();
                    setOpenDropdownMenu(false);
                  }}
                />
              </div>
            </div>
          ),
        },
      ]}
    />
  );

  return (
    <Dropdown
      className="dropdown_menu_tesstt"
      overlay={menu}
      trigger={['click']}
      onOpenChange={handleOpenChangeMenu}
      open={openDropdownMenu}
    >
      <BaseButtonPrimary className="px-4 d-md-none" prefixDefault="menu" style={{ height: 35, minHeight: 'auto' }} />
    </Dropdown>
  );
};

export default React.memo(DropdownMenu);
