import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import CasinoHackLogo from '../../assets/images/casinohack-logo-w.png';
import Evolution from '../../assets/images/evolution.png';
import AECasino from '../../assets/images/aecasino.png';
import AEGaming from '../../assets/images/asiagaming.png';
import SAGaming from '../../assets/images/sagaming.png';
import GamePlay from '../../assets/images/gameplay.png';
import AllBet from '../../assets/images/allbet.png';
import DreamGaming from '../../assets/images/dreamgaming.png';
import WmCasino from '../../assets/images/wmcasino.png';
import BigGamingLogo from '../../assets/images/Big-Gaming-logo.png';
import EbetLogo from '../../assets/images/Ebet-logo.png';
import TelegramInfo from 'src/components/Common/TelegramInfo';

const LoginAndRegister = (props: any) => {
  const { t } = useTranslation();
  const content = [
    t('label:auth.infor_content_one'),
    t('label:auth.infor_content_two'),
    t('label:auth.infor_content_three'),
    t('label:auth.infor_content_four'),
    t('label:auth.infor_content_five'),
  ];

  return (
    <div className="main-login-box">
      <Row className="col-high d-flex flex-column-reverse flex-md-row mx-4 mx-md-0">
        <Col className="left-style" span={24} md={12} lg={14}>
          <Row>
            <Col span={24} className="d-none d-md-block mb-4">
              <img src={CasinoHackLogo} alt="logo" />
            </Col>
            <Col span={24} lg={21}>
              <h2 className="container-form-auth__title  mt-4 mt-md-5">{t('label:auth.infor_title')}</h2>
              <div className="container-form-auth__infor">
                {content.map((str: string, k: number) => {
                  if (str !== '') {
                    return <p key={k}>{str}</p>;
                  }
                  return false;
                })}
              </div>
            </Col>
            <Col className="me-0 me-lg-3 footer-connect-us d-connect-us align-items-center">
              <label className="fs-6 me-3">{t('label:connect_us')}:</label>
              <TelegramInfo />
            </Col>
          </Row>

          <Row className="mt-5 align-items-center" gutter={16}>
            <Col className="me-0 me-lg-3 " span={6} lg={5}>
              <img className="footer-logo" src={Evolution} alt="img_footer" />
            </Col>
            <Col className="me-0 me-lg-3" span={6} lg={5}>
              <img className="footer-logo" src={AECasino} alt="img_footer" />
            </Col>
            <Col className="me-0 me-lg-3" span={6} lg={5}>
              <img className="footer-logo" src={AEGaming} alt="img_footer" />
            </Col>
            <Col className="me-0 me-lg-3" span={6} lg={5}>
              <img className="footer-logo" src={SAGaming} alt="img_footer" />
            </Col>
          </Row>
          <Row className="mt-3 align-items-center" gutter={16}>
            <Col className="me-0 me-lg-3" span={6} lg={5}>
              <img className="footer-logo" src={GamePlay} alt="img_footer" />
            </Col>
            <Col className="me-0 me-lg-3" span={6} lg={5}>
              <img className="footer-logo" src={AllBet} alt="img_footer" />
            </Col>
            <Col className="me-0 me-lg-3" span={6} lg={5}>
              <img className="footer-logo" src={DreamGaming} alt="img_footer" />
            </Col>
            <Col className="me-0 me-lg-3" span={6} lg={5}>
              <img className="footer-logo" src={WmCasino} alt="img_footer" />
            </Col>
          </Row>
          <Row className="mt-3 align-items-center" gutter={16}>
            <Col className="me-0 me-lg-3" span={6} lg={5}>
              <img className="footer-logo" src={BigGamingLogo} alt="img_footer" />
            </Col>
            <Col className="me-0 me-lg-3" span={6} lg={5}>
              <img className="footer-logo" src={EbetLogo} alt="img_footer" />
            </Col>
          </Row>
        </Col>

        <Col className="container-form-auth" span={24} md={12} lg={10}>
          {props.children}
        </Col>
      </Row>
    </div>
  );
};

export default LoginAndRegister;
