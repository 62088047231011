import { Col, ModalProps, Row, Select } from 'antd';
import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommonButton from 'src/components/Common/CommonButton';
import ModalCustom from 'src/components/Common/ModalCustom';
import { getProviders, setProviders } from 'src/stores/app/app.service';

const ProviderSetting = (props: { onError?: any; onCancel?: any } & ModalProps) => {
  const { t } = useTranslation();
  const [listProviders, setListProviders] = useState([]);

  useEffect(() => {
    getProviders()
      .then((res: any) => {
        setListProviders(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const reducer = (state: any, action: any) => {
    return {
      ...state,
      [action.type]: action.value,
    };
  };

  const [providerStatus, dispatch] = useReducer(reducer, {});

  const saveProviderHandle = async () => {
    const payload = listProviders.map((item: any) => {
      if (providerStatus.hasOwnProperty(item.provider)) {
        return { ...item, show: providerStatus[item.provider] };
      }
      return item;
    });

    try {
      const callSetProvider = await setProviders(encodeURIComponent(JSON.stringify(payload, null, 2)));
      if (callSetProvider.status === 200) {
        props.onCancel();
      }
    } catch (err) {
      props.onError((err as any)?.response?.statusText || 'unknow error');
    }
  };

  return (
    <ModalCustom title={t('label:admin.provider_setting')} wrapClassName="" {...props}>
      <Row className="text-light">
        {listProviders.map((provider: any) => {
          return (
            <Col key={provider.provider} span={12} className="mb-2">
              <label htmlFor={provider.provider} className="me-1 fs-6 w-25 text-orange">
                {provider.provider}
              </label>
              <Select
                className="rounded bg-white w-50"
                id={provider.provider}
                defaultValue={provider.show}
                options={[
                  {
                    value: false,
                    label: t('label:btn.hide'),
                  },
                  {
                    value: true,
                    label: t('label:btn.show'),
                  },
                ]}
                onChange={(value) => dispatch({ type: provider.provider, value })}
              />
            </Col>
          );
        })}
      </Row>
      <Row>
        <CommonButton className="btn-setting w-25 mx-auto" onClick={saveProviderHandle}>
          {t('label:btn.save')}
        </CommonButton>
      </Row>
    </ModalCustom>
  );
};

export default ProviderSetting;
