import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from 'antd';
import React, { useEffect, useState } from 'react';
import CreditAmountCpn from '../Common/CreditAmountCpn';
import SwitchCustom from '../Common/SwitchCpn/SwitchCustom';
import NoteCreditInfo from '../Common/NoteCreditInfo';
import { useTranslation } from 'react-i18next';

export interface CollapseCardProps {
  children?: any;
  header?: React.ReactNode;
  onlyOpen?: boolean;
  hideExpan?: boolean;
  hideVolume?: boolean;
  showLabelExpan?: boolean;
  className?: string;
  isMobile?: boolean;
  onPlaySound?: (val: boolean) => void;
  openSound?: boolean;
  showAmountCredit?: boolean;
  checkedSwitch?: boolean;
  onChangeSwitch?: (val: boolean) => void;
  showSwitch?: boolean;
  showInfo?: boolean;
}

const { Panel } = Collapse;

const CollapseCard = (props: CollapseCardProps) => {
  const { t } = useTranslation();
  const {
    children,
    header,
    onlyOpen,
    hideVolume,
    hideExpan,
    showLabelExpan,
    className,
    isMobile,
    onPlaySound,
    openSound,
    showAmountCredit,
    checkedSwitch,
    onChangeSwitch,
    showSwitch,
    showInfo,
  } = props;
  const [activeKeys, setActiveKeys] = useState<any>(['1']);
  const [soundOpen, setSoundOpen] = useState<boolean>(false);

  useEffect(() => {
    if (openSound) {
      setSoundOpen(openSound);
    }
  }, [openSound]);

  const onChange = () => {
    if (onlyOpen) return;
    if (activeKeys.includes('1')) {
      setActiveKeys([]);
    } else {
      setActiveKeys(['1']);
    }
  };

  const renderExpanIcon = () => {
    return (
      <div className="d-flex align-items-center">
        {showAmountCredit && <CreditAmountCpn className="d-none d-md-flex" hideRefreshCredit={true} />}
        {showSwitch && <SwitchCustom className="d-none d-md-flex" checked={checkedSwitch} onChange={onChangeSwitch} />}
        {showInfo && <NoteCreditInfo className="d-none d-md-flex" />}
        {!hideVolume && (
          <div
            onClick={() => {
              onPlaySound && onPlaySound(!soundOpen);
              setSoundOpen(!soundOpen);
            }}
          >
            {soundOpen ? (
              <FontAwesomeIcon color="white" icon={faVolumeUp} className="d-flex align-items-center px-2" size="2x" />
            ) : (
              <FontAwesomeIcon color="white" icon={faVolumeMute} className="d-flex align-items-center px-2" size="2x" />
            )}
          </div>
        )}
        {!hideExpan ? (
          <div className="d-flex align-items-center ms-2" onClick={onChange}>
            {showLabelExpan && (
              <span className="label-expan ms-2">
                {activeKeys?.includes('1') ? t('label:btn.hide') : t('label:btn.show')}
              </span>
            )}
            {activeKeys?.includes('1') ? (
              <UpOutlined className="ms-2" style={{ color: 'white' }} />
            ) : (
              <DownOutlined className="ms-2" style={{ color: 'white' }} />
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="w-100 h-100 d-flex align-items-center" onClick={onChange}>
        {header}
      </div>
    );
  };

  return (
    <Collapse
      defaultActiveKey={['1']}
      className={`collapse-card-custom ${activeKeys?.includes('1') && isMobile ? 'collapse-card-custom__open' : ''} ${
        className || ''
      }`}
      activeKey={onlyOpen ? ['1'] : activeKeys}
      expandIconPosition="end"
    >
      <Panel header={renderHeader()} key="1" extra={renderExpanIcon()} showArrow={false}>
        {children}
      </Panel>
    </Collapse>
  );
};

export default CollapseCard;
