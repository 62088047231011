import { Col, Divider, Form, notification, Row, Grid } from 'antd';
import React, { useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CommonButton from 'src/components/Common/CommonButton';
import LoginAndRegister from 'src/components/Common/LoginAndRegister';
import PhoneCodeList from 'src/components/Common/PhoneCodeList';
import { forgotPasswordServices, forgotPasswordVerifyOtpServices } from 'src/stores/auth/auth.service';
import Countdown from 'react-countdown';
import ErrorPopup from '../Admin/ErrorPopup';
import { formatPhoneNumber, getKeyMultiLanguageLabelErrorByCode, phoneValidator } from 'src/helpers/utils';
import { REGION_BLOCK_REGISTER_ACC } from 'src/helpers/constanst';

const ResetPassword = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [formOTP] = Form.useForm();
  const [isWaitingReset, setIsWaiting] = useState<boolean>(false);
  const captchaRef = useRef<any>(null);
  const [isResetEnable, setResetEnable] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<any>();
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const { sm: isSm } = Grid.useBreakpoint();

  const onResetHandle = async (value: any) => {
    const phone = formatPhoneNumber(value.mobile);

    await forgotPasswordServices(`${value.phoneCode}${phone}`)
      .then((res) => {
        if (res && res.status === 200) {
          setIsWaiting(true);
          refCoundown?.current?.start();
        }
      })
      .catch((error) => {
        const errorData: any = (error as any)?.response?.data;
        if (errorData?.code === 502) {
          const errorCodeLanguageKey = getKeyMultiLanguageLabelErrorByCode(errorData?.code);
          setErrorModal({ title: 'Errors', message: t(errorCodeLanguageKey) });
        } else {
          setErrorModal({ title: 'Errors', message: errorData?.message ?? t('label:message.something_when_wrong') });
        }
        setErrorModalOpen(true);
      });
  };

  const onVerifyOtp = async (val: any) => {
    const valReset = form.getFieldsValue();
    if (!valReset?.mobile || !valReset?.phoneCode) {
      form.submit();
      return;
    }
    const phone = formatPhoneNumber(valReset.mobile);
    const username = `${valReset.phoneCode}${phone}`;
    await forgotPasswordVerifyOtpServices(username, val.OtpCode)
      .then((res) => {
        if (res && res.status === 200) {
          form.resetFields();
          formOTP.resetFields();
          notification.success({
            message: t('label:message.success'),
            description: t('label:notifycation.reset_password_succes'),
          });
        }
      })
      .catch((error) => {
        const errorCode: any = (error as any)?.response?.data?.code;
        const errorMess: any = (error as any)?.response?.data?.message;
        if (errorCode === 500) {
          const errorCodeLanguageKey = getKeyMultiLanguageLabelErrorByCode(errorCode);
          setErrorModal({ title: 'Errors', message: t(errorCodeLanguageKey) });
        } else {
          setErrorModal({ title: 'Errors', message: errorMess });
        }
        setErrorModalOpen(true);
      });
  };

  //countdown reset
  const refCoundown = useRef<any>(null);
  const defaultTImeCount = 120000;

  const rendererCountDown = ({ hours, minutes, seconds, completed }: any) => {
    if (isWaitingReset)
      return (
        <Col className="mt-2">
          <span style={{ color: 'red' }}>
            {minutes}:{seconds}
          </span>
        </Col>
      );
    return null;
  };

  const countdownMemo = useMemo(() => {
    return (
      <Countdown
        ref={refCoundown}
        date={Date.now() + defaultTImeCount}
        renderer={rendererCountDown}
        autoStart={false}
        onComplete={() => {
          setIsWaiting(false);
          refCoundown?.current?.stop();
        }}
      />
    );
    // eslint-disable-next-line
  }, [isWaitingReset]);

  return (
    <LoginAndRegister>
      <Row className="mb-5 d-none d-md-flex">
        <Col
          span={3}
          className="d-none d-md-block"
          style={{
            width: '100%',
            borderTop: '6px solid #5e3ea6',
          }}
        />
      </Row>

      <Form initialValues={{ phoneCode: '60' }} form={form} onFinish={onResetHandle}>
        <Row>
          <Col>
            <h2 className="text-light mt-4 mt-md-5">{t('label:auth.reset_password_label')}</h2>
          </Col>
        </Row>
        <Row className="mt-2 mt-md-0" gutter={14}>
          <Col span={10} md={8}>
            <Form.Item
              name="phoneCode"
              rules={[
                {
                  required: true,
                  message: t('errors:auth.code_req') || '',
                },
              ]}
            >
              <PhoneCodeList hideCountry={REGION_BLOCK_REGISTER_ACC} />
            </Form.Item>
          </Col>
          <Col span={14} md={16}>
            <Form.Item
              name="mobile"
              rules={[
                {
                  validator: (_, value) => phoneValidator(form.getFieldValue('phoneCode'), value),
                },
              ]}
            >
              <input
                type="text"
                id="mobileNumber"
                className="form-control form-input-style py-2"
                placeholder={t('label:auth.mobile_number_label')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ReCAPTCHA
              className="g-recaptcha"
              theme="dark"
              ref={captchaRef}
              sitekey={process.env.REACT_APP_SITE_KEY || ''}
              onChange={() => {
                if (captchaRef?.current?.getValue()) {
                  setResetEnable(true);
                }
              }}
              onExpired={() => setResetEnable(false)}
            />
          </Col>
        </Row>
        {countdownMemo}
        <Row className="my-3">
          <Col span={11}>
            <CommonButton type="submit" className="fw-bold w-100 h-100" disabled={!isResetEnable || isWaitingReset}>
              <span style={{ color: '#FFFFFF' }}>{t('label:auth.reset_label').toLocaleUpperCase()}</span>
            </CommonButton>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <Link to="/login">
              <button type="button" className="btn btn-dark fw-bold w-100 " style={{ borderRadius: '20px' }}>
                <span style={{ color: '#FFFFFF' }}>{t('label:auth.back_label').toLocaleUpperCase()}</span>
              </button>
            </Link>
          </Col>
        </Row>
      </Form>

      <Divider style={{ backgroundColor: '#271843' }} />

      <Form form={formOTP} onFinish={onVerifyOtp}>
        <Row className="my-3">
          <Col span={24} sm={11}>
            <Form.Item
              className="mb-0"
              name={'OtpCode'}
              rules={[
                {
                  required: true,
                  message: t('errors:auth.code_req') || '',
                },
              ]}
            >
              <input
                type="text"
                id="otpCode"
                className="form-control form-input-style py-2"
                placeholder={t('label:auth.enter_otp_code')}
              />
            </Form.Item>
          </Col>
          <Col sm={2} />
          <Col span={24} sm={11}>
            <CommonButton type="submit" className={`fw-bold w-100 h-auto ${isSm ? 'mt-1' : 'mt-3'}`}>
              <span style={{ color: '#FFFFFF' }}>{t('label:auth.verify_label')}</span>
            </CommonButton>
          </Col>
          <Col span={24} className="py-3">
            <p style={{ color: '#dccef0' }}>{t('label:auth.note_reset_password')}</p>
          </Col>
        </Row>
      </Form>

      <ErrorPopup messageError={errorModal?.message} open={errorModalOpen} onCancel={() => setErrorModalOpen(false)} />
    </LoginAndRegister>
  );
};

export default ResetPassword;
