/* eslint-disable prettier/prettier */
import React from 'react';
import { Row, Switch } from 'antd';
import './switch.css';
import { useTranslation } from 'react-i18next';
interface Props {
  checked?: boolean;
  onChange?: (val: boolean) => void;
  className?: string;
}

const SwitchCustom = (props: Props) => {
  const { t } = useTranslation();

  const handleChange = (val: any) => {
    props.onChange && props.onChange(val);
  };
  return (
    <Row className={`custom-switch w-max-content ${props.className ? props.className : ''}`}>
      <Switch checked={props.checked} onChange={handleChange} className={`me-2  ${props.checked ? 'bg-green' : ''}`} />
      <span className="text-small font-medium text-green">{props.checked ? t("label:dashboard.note_credit_active") : t("label:dashboard.note_credit_inactive") }</span>
    </Row>
  );
};

export default SwitchCustom;
