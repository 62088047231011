import { AppThunk } from '..';
import { createAmountSettingServices, depositsServices, memberCurrencyServices } from './app.service';
import { memberCurrencyActionSlice, mountSettingActionSlice } from './app.slice';

export const memberCurrencyAction = (): AppThunk => async (dispatch) => {
  const response: any = await memberCurrencyServices();
  if (response && response.data && response.status === 200) {
    dispatch(memberCurrencyActionSlice(response.data));
  }
};

export const createAmountSettingAction = (): AppThunk => async (dispatch) => {
  const response: any = await createAmountSettingServices();
  if (response && response.data && response.status === 200) {
    dispatch(mountSettingActionSlice(response.data));
  }
};

export const depositsAction = (payload: any): AppThunk => async () => {
  const response: any = await depositsServices(payload);
  if (response && response.data && response.status === 200) {
    window.open(response.data.depositPostUrl, '_blank', 'noopener,noreferrer');
  }
};
