import { DownOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Row, Spin } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import DashboardLayout from 'src/components/Common/DashboardLayout';
import DonutChart from 'src/components/Common/DonutChart';
import {
  deductCreditServices,
  getFormulaServices,
  getPredictionsServices,
  getTableServices,
} from 'src/stores/app/app.service';
import sound from '../../assets/audio/liveAlert.mp3';
import CardTypeOne from './CardTypeOne';
import CardTypeTwo from './CardTypeOneTwo';
import MenuMobileDashboard from './menuMobile';
import SidebarDesktopDashboard from './SidebarDesktop';
import { MenuType } from './type';

import _ from 'lodash';
import { useTypedDispatch } from 'src/hooks/useTypedDispatch';
import { memberWalletAction } from 'src/stores/auth/auth.actions';
import { memberWallerServices } from 'src/stores/auth/auth.service';
import ErrorPopup from '../Admin/ErrorPopup';
import FilterBar, { filterFmlData, sortKeys } from './FilterBar';
// import bannerJson from 'src/pages/Admin/BannerSetting/banner.json';
// import { PositionBanner } from '../Admin/BannerSetting';

interface ViewedTableType {
  tableCode: string;
  currentRoadMapArr: Array<string>;
  newRoadMapArr?: Array<string>;
  predictionBodyListDTO?: any;
  tableName: string;
  deductedCredit?: boolean;
}

interface fmlProps {
  id: string | number;
  name: string;
}

function Dashboard() {
  // const bannerBottomDesktop = bannerJson.banners.find(
  //   (item) => item.position === PositionBanner.DASHBOARD_BOTTOM_DESKTOP,
  // );
  // const bannerBottomMobile = bannerJson.banners.find(
  //   (item) => item.position === PositionBanner.DASHBOARD_BOTTOM_MOBILE,
  // );

  const location: any = useLocation<any>();
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const sortDefault = sortKeys.WinRateHighToLow;
  const audioElement = new Audio(sound);
  const [openSound, setOpenSound] = useState<boolean>(false);
  const [deActiveTabble, setDeActiveTabble] = useState<boolean>();
  const [showModalRedirect, setSowModalRedirect] = useState({ open: false, message: '' });
  const [selectedProvider, setSelectedProvider] = useState<MenuType>(location?.query?.item || MenuType.EVOLUTION);
  const [formulas, setFormulas] = useState([]);
  const [tablesRes, setTablesRes] = useState<any>();
  const [tables, setTables] = useState([]);
  const [selectedFml, setSelectedFml] = useState<any>();
  const [selectedTable, setSelectedTable] = useState<any>();
  const [viewedTable, setViewedTable] = useState<Array<ViewedTableType>>();
  const [filterTable, setFilterTable] = useState([]);
  const [formulaLoading, setFormulaLoading] = useState(false);
  const [tableLoading, settableLoading] = useState(false);
  const [sortedItem, setSortedItem] = useState<sortKeys>(sortDefault);
  const [searchValue, setSearchValue] = useState<string>('');

  // reset filter bar
  const resetFilter = () => {
    setSortedItem(sortDefault);
    setSearchValue('');
  };

  const filterTableHandle = (tbl: any, fml: any) => {
    const filteredTable = tbl.filter((table: any) => table?.formulaName === fml?.name);
    setFilterTable(filteredTable);
  };

  const computeWinRate = (providerPredictList: any) => {
    for (let x = 0; x < providerPredictList.length; x++) {
      const predictOucome = JSON.parse(providerPredictList[x].predictResult!);
      const roadMapArr = JSON.parse('[' + providerPredictList[x].roadMap! + ']');
      const length = roadMapArr.length;
      if (length > 0) {
        providerPredictList[x].winRate = predictOucome[length - 1].percentage;
      }
    }
    return providerPredictList;
  };

  const refIntervalGetTable = useRef<any>(null);

  useEffect(() => {
    setSelectedFml(undefined);
    setSelectedTable(undefined);
    setFilterTable([]);
    setDeActiveTabble(undefined);
    setViewedTable(undefined);

    setFormulaLoading(true);
    const listFormula = getFormulaServices();
    const listTable = getTableServices(selectedProvider);

    Promise.all([listFormula, listTable])
      .then((responses) => {
        const [resFormula, resTable] = responses;
        if (resFormula.data.length > 0) {
          setFormulas(resFormula.data);
          const providerPredictList = computeWinRate(resTable.data!);
          setTables(providerPredictList);
        }
      })
      .catch((err) => {
        setFormulas([]);
        setTables([]);
      })
      .finally(() => setFormulaLoading(false));

    clearInterval(refIntervalGetTable.current);
    refIntervalGetTable.current = setInterval(async () => {
      getTableServices(selectedProvider).then((responses) => {
        if (responses?.data) {
          setTablesRes(() => {
            return responses?.data;
          });
        }
      });
    }, 40000);

    return () => {
      clearInterval(refIntervalGetTable.current);
      clearInterval(refIntervalGetPrediction.current);
    };
  }, [selectedProvider]);

  useEffect(() => {
    const providerPredictList = computeWinRate(tablesRes || []);
    setTables(providerPredictList);
  }, [tablesRes]);

  const refIntervalGetPrediction = useRef<any>(null);

  useEffect(() => {
    if (selectedProvider && selectedFml && selectedFml.name && selectedTable && selectedTable.tableCode) {
      if (deActiveTabble) {
        getPredictionsServices(selectedProvider, selectedTable.tableCode, selectedFml.name).then((responses) => {
          if (responses?.data) {
            setViewedTable((oldViewedTables?: Array<ViewedTableType>) => {
              return oldViewedTables?.map((oldTable) => {
                if (oldTable.tableCode === selectedTable.tableCode) {
                  let deductedCredit = oldTable.deductedCredit;
                  let newRoadMapArr = oldTable?.currentRoadMapArr;
                  if (!_.isEqual(oldTable?.currentRoadMapArr, responses?.data.roadMapArr)) {
                    newRoadMapArr = responses?.data.roadMapArr;
                    deductedCredit = false;
                  }
                  return {
                    ...oldTable,
                    predictionBodyListDTO: responses?.data.predictionBodyListDTO,
                    deductedCredit,
                    newRoadMapArr,
                  };
                } else {
                  return oldTable;
                }
              });
            });
          }
        });
        clearInterval(refIntervalGetPrediction.current);
      } else {
        getPredictionsServices(selectedProvider, selectedTable.tableCode, selectedFml.name).then((responses) => {
          if (responses?.data) {
            setViewedTable((oldViewedTables?: Array<ViewedTableType>) => {
              return oldViewedTables?.map((oldTable) => {
                if (oldTable.tableCode === selectedTable.tableCode) {
                  let newRoadMapArr = oldTable?.newRoadMapArr;
                  let currentRoadMapArr = oldTable?.currentRoadMapArr;
                  if (!_.isEqual(oldTable?.currentRoadMapArr, responses?.data.roadMapArr)) {
                    newRoadMapArr = responses?.data.roadMapArr;
                    currentRoadMapArr = responses?.data.roadMapArr;
                    checkDeductCredit();
                  }
                  return {
                    ...oldTable,
                    deductedCredit: true,
                    predictionBodyListDTO: responses?.data.predictionBodyListDTO,
                    currentRoadMapArr,
                    newRoadMapArr,
                  };
                } else {
                  return oldTable;
                }
              });
            });
          }
        });
        clearInterval(refIntervalGetPrediction.current);
        refIntervalGetPrediction.current = setInterval(async () => {
          getPredictionsServices(selectedProvider, selectedTable.tableCode, selectedFml.name).then((responses) => {
            if (responses?.data) {
              setViewedTable((oldViewedTables?: Array<ViewedTableType>) => {
                return oldViewedTables?.map((oldTable) => {
                  if (oldTable.tableCode === selectedTable.tableCode) {
                    let newRoadMapArr = oldTable?.newRoadMapArr;
                    let currentRoadMapArr = oldTable?.currentRoadMapArr;
                    if (!_.isEqual(oldTable?.currentRoadMapArr, responses?.data.roadMapArr)) {
                      newRoadMapArr = responses?.data.roadMapArr;
                      currentRoadMapArr = responses?.data.roadMapArr;
                      checkDeductCredit();
                    }
                    return {
                      ...oldTable,
                      deductedCredit: true,
                      predictionBodyListDTO: responses?.data.predictionBodyListDTO,
                      currentRoadMapArr,
                      newRoadMapArr,
                    };
                  } else {
                    return oldTable;
                  }
                });
              });
            }
          });
        }, 3000);
      }
    } else {
      clearInterval(refIntervalGetPrediction.current);
    }

    return () => {
      clearInterval(refIntervalGetPrediction.current);
    };
    // eslint-disable-next-line
  }, [selectedTable, deActiveTabble]);

  const checkDeductCredit = async () => {
    const resWallet: any = await memberWallerServices().catch(() => {
      clearInterval(refIntervalGetTable.current);
      clearInterval(refIntervalGetPrediction.current);
      showErrorRedirect();
    });

    if (resWallet?.data?.credit > 0) {
      deductCreditServices()
        .then((res) => {
          openSound && audioElement.play();
          dispatch(memberWalletAction());
          if (res.data) {
            if (res.data?.credit <= 0) {
              clearInterval(refIntervalGetTable.current);
              clearInterval(refIntervalGetPrediction.current);
              showInsufficientRedirect();
            }
          }
        })
        .catch(() => {
          clearInterval(refIntervalGetTable.current);
          clearInterval(refIntervalGetPrediction.current);
          showErrorRedirect();
        });
    } else {
      clearInterval(refIntervalGetTable.current);
      clearInterval(refIntervalGetPrediction.current);
      showInsufficientRedirect();
    }
  };

  useEffect(() => {
    if (selectedFml) {
      filterTableHandle(tables, selectedFml);
    }
  }, [tables, selectedFml]);

  const clickFormulaHandle = (fml: any) => {
    settableLoading(true);
    setSelectedFml(fml);
    filterTableHandle(tables, fml);
    setSelectedTable(undefined);
    settableLoading(false);
    resetFilter();
  };

  const clickTableHandle = (table: any) => {
    setSelectedTable(table);
    const convertTable = {
      tableCode: table.tableCode,
      tableName: table.tableName,
      currentRoadMapArr: table.roadMap,
      newRoadMapArr: table.roadMap,
    };
    setViewedTable((oldViewedTable?: Array<ViewedTableType>) => {
      if (!oldViewedTable) {
        return [convertTable];
      } else {
        const indexTable = oldViewedTable.findIndex((oldTable) => oldTable.tableCode === table.tableCode);
        if (indexTable > -1) {
          return oldViewedTable;
        } else {
          return [...oldViewedTable, convertTable];
        }
      }
    });
  };

  const menuSelectHanlde = (item: MenuType) => {
    setSelectedProvider(item);
    resetFilter();
  };

  const showErrorRedirect = () => {
    setSowModalRedirect({ open: true, message: t('label:dashboard.error_deduct_credit') });
  };

  const showInsufficientRedirect = () => {
    setSowModalRedirect({ open: true, message: t('label:dashboard.insufficient_credit') });
  };

  const onChangeSound = (val: boolean) => {
    setOpenSound(val);
  };

  const handleActivePrediction = (val: boolean) => {
    setDeActiveTabble(!val);
  };

  const viewedTableByCode = useMemo(() => {
    if (selectedTable?.tableCode && viewedTable && viewedTable.length > 0) {
      return viewedTable.find((table) => table.tableCode === selectedTable.tableCode);
    }
    return undefined;
  }, [viewedTable, selectedTable?.tableCode]);

  // const onClickBanner = (url: string) => () => {
  //   window.open(url, '_blank');
  // };

  const fmlData = filterFmlData(filterTable, sortedItem, searchValue);

  return (
    <DashboardLayout>
      <Row className="dashboard-center-width m-auto h-100">
        <SidebarDesktopDashboard menuSelected={selectedProvider} onMenuSelected={menuSelectHanlde} />
        <Col className="dashboard-content-D p-0 p-md-4">
          <MenuMobileDashboard menuSelected={selectedProvider} onMenuSelected={menuSelectHanlde} />
          <Row className="mt-2 mt-md-0" gutter={16}>
            <Col span={24} lg={8} className="mt-1">
              <CardTypeOne header={t('label:dashboard.title.choose_formula')} className="hide-padding-collapse">
                <div className={`list-formula mt-3 ${formulaLoading ? 'd-flex justify-content-center' : ''}`}>
                  {formulaLoading ? (
                    <Spin tip="Loading..." className="my-auto" />
                  ) : (
                    formulas.map((fml: fmlProps) => {
                      const { id, name } = fml;
                      const formatFormulaName = t(`label:dashboard.title.${name?.toString().toLowerCase()}`);
                      return (
                        <div
                          onClick={() => clickFormulaHandle(fml)}
                          key={id}
                          className={`formula px-2 py-2 d-flex flex-row align-items-center cursor-pointer ${
                            id === selectedFml?.id ? 'item-selected' : ''
                          }`}
                        >
                          <Avatar
                            size={19}
                            className={`mx-1 ${id === selectedFml?.id ? 'bg-warning' : 'circle-bg-pink'}`}
                          >
                            E
                          </Avatar>
                          <span
                            className={`px-2 py-1 formula-item ${
                              id === selectedFml?.id ? 'formula-item-selected text-decoration-underline' : ''
                            }`}
                          >
                            {formatFormulaName}
                          </span>
                          <span className="ms-auto fw-bold text-green text-smaller">
                            {t('label:dashboard.online').toLocaleUpperCase()}
                          </span>
                        </div>
                      );
                    })
                  )}
                </div>
              </CardTypeOne>
            </Col>
            {selectedFml && (
              <Col span={24} lg={16} className="mt-1 ">
                <CardTypeOne>
                  <FilterBar
                    key={selectedFml?.id}
                    sortedItem={sortedItem}
                    searchValue={searchValue}
                    onSortChange={setSortedItem}
                    onSearchChange={setSearchValue}
                  />
                  <Row className="flex-nowrap overflow-auto list-table-dashboard pb-3 w-auto">
                    {tableLoading ? (
                      <Spin tip="Loading..." className="m-auto" />
                    ) : (
                      fmlData.map((tab: any) => {
                        const { tableName, winRate } = tab;
                        const strokeIndicatorColor =
                          winRate === 100
                            ? '#f14b4b'
                            : winRate >= 90
                            ? '#75ee49'
                            : winRate > 70
                            ? '#beee4a'
                            : '#efe949';
                        return (
                          <Col key={tableName} className="w-auto me-3">
                            <div
                              className={`cursor-pointer table-card d-flex flex-column align-items-center ${
                                selectedTable?.tableName === tableName ? 'table-card-selected-bg' : ''
                              }`}
                              onClick={() => clickTableHandle(tab)}
                            >
                              <div className="fw-bold formula-item-selected text-center pb-2 pt-3 text-smaller w-100 px-2 mh-80">
                                {t('label:dashboard.baccarat').toLocaleUpperCase()} {tableName}
                              </div>
                              <DonutChart
                                value={winRate.toFixed(0) || 0}
                                size={80}
                                strokewidth={11}
                                strokeIndicator={strokeIndicatorColor}
                              />
                              <div className="card-body py-2 w-100 text-center"> {t('label:dashboard.winRate')}</div>
                            </div>
                            {selectedTable?.tableName === tableName && (
                              <Button className="button-select-table-card mt-2">
                                <div className="d-flex flex-column fw-bold text-smaller align-items-center">
                                  <span>{t('label:btn.selected')}</span>
                                  <DownOutlined />
                                </div>
                              </Button>
                            )}
                          </Col>
                        );
                      })
                    )}
                  </Row>
                </CardTypeOne>
              </Col>
            )}
          </Row>

          <Col className="mt-1 mt-md-4" span={24}>
            {selectedTable && viewedTableByCode && viewedTableByCode.predictionBodyListDTO && (
              <CardTypeTwo
                title={`${t('label:dashboard.baccarat').toLocaleUpperCase()} ${selectedTable?.tableName}`}
                winrate={selectedTable?.winRate}
                predictions={{
                  predictionBodyListDTO: viewedTableByCode.predictionBodyListDTO,
                  roadMapArr: viewedTableByCode.newRoadMapArr
                    ? viewedTableByCode.newRoadMapArr
                    : viewedTableByCode.currentRoadMapArr,
                }}
                formulaId={selectedFml}
                onChangeSound={onChangeSound}
                openSound={openSound}
                showAmountCredit={true}
                showSwitch={true}
                checkedSwitch={!deActiveTabble}
                onChangeSwitch={handleActivePrediction}
                filterGrayAll={!deActiveTabble}
                classNameCollapse="cardTypeTwo"
                hideVolume={true}
                showInfo={true}
                hideNextRound={!viewedTableByCode.deductedCredit}
              />
            )}
          </Col>

          {/* {bannerBottomDesktop && bannerBottomDesktop.url && (
            <div
              className="banner d-none d-md-block mt-md-3 mb-4 px-3 px-md-0"
              onClick={onClickBanner(bannerBottomDesktop.url)}
            >
              <img className="banner-image" src={bannerBottomDesktop.image} alt="banner" />
            </div>
          )}
          {bannerBottomMobile && bannerBottomMobile.url && (
            <div className="banner d-md-none mt-md-3 mb-4 px-3 px-md-0" onClick={onClickBanner(bannerBottomMobile.url)}>
              <img className="banner-image" src={bannerBottomMobile.image} alt="banner" />
            </div>
          )} */}
        </Col>
      </Row>
      <ErrorPopup messageError={showModalRedirect?.message} open={showModalRedirect.open} redirectToLobby={true} />
    </DashboardLayout>
  );
}

export default Dashboard;
