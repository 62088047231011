import _ from 'lodash';
import { WallerEntity } from 'src/stores/auth/model';
import { useTypedSelector } from './useTypedSelector';

export const useCurrentUserWallet = (): WallerEntity | undefined | null => {
  return useTypedSelector((state) => {
    const result = _.cloneDeep(state.auth.wallet);
    if (result) {
      return result;
    }
    return null;
  });
};
