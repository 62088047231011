/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { formatMoney } from 'src/helpers/utils';
import Refresh from '../../assets/images/refresh.svg';
import Dollar from '../../assets/images/$.png';
import { useCurrentUserWallet } from 'src/hooks/useCurrentUserWallet';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import ModalCustom from './ModalCustom';
import { Button, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { getUser } from 'src/stores/app/app.service';
import ErrorPopup from 'src/components/Common/ErrorPopup';

interface CreditAmountType {
  onClick?: (e?: any) => void;
  onRefresh?: (e?: any) => void;
  hideRefreshCredit?: boolean;
  className?: string;
}

const CreditAmountCpn = (props: CreditAmountType) => {
  const { t } = useTranslation();
  const headerIconSize = 20;
  const wallet = useCurrentUserWallet();
  const currentUser = useCurrentUser();

  const [showMessageDepositNotAllow, setShowMessageDepositNotAllow] = useState<boolean>(false);
  const [errorGetData, setErrorGetData] = useState<boolean>(false);

  const handlerClick = async () => {
    currentUser?.login &&
      (await getUser(currentUser?.login?.toString())
        .then((res) => {
          const { data } = res;
          if (!!data.paymentAllowed) {
            props.onClick && props.onClick();
          } else {
            setShowMessageDepositNotAllow(true);
          }
        })
        .catch(() => {
          setErrorGetData(true);
        }));
  };

  const handlerRefresh = (e?: any) => {
    props.onRefresh && props.onRefresh(e);
  };

  return (
    <>
      <div
        onClick={handlerClick}
        className={`badge d-flex justify-content-end align-items-center mx-3 fs-6 ${
          props.className ? props.className : ''
        }`}
        style={{ backgroundColor: '#26212f', color: '#e39425' }}
      >
        <img width={headerIconSize} height={headerIconSize} src={Dollar} alt="" />
        <span className="mx-2">{formatMoney(wallet?.credit, 2)}</span>
        {!props.hideRefreshCredit && (
          <img
            role="button"
            width={headerIconSize}
            height={headerIconSize}
            src={Refresh}
            alt=""
            onClick={handlerRefresh}
          />
        )}
      </div>

      {showMessageDepositNotAllow && (
        <ModalCustom open={showMessageDepositNotAllow} closable={false}>
          <Row>
            <p className="w-100 text-center mt-3 ws-pre-wrap text-white">{t('label:dashboard.message_no_deposit')}</p>
            <div className="w-100 d-flex justify-content-center mt-2">
              <Button
                onClick={() => setShowMessageDepositNotAllow(false)}
                className="d-flex align-items-center justify-content-center flex-nowrap base_button_primary text-white fw-bold px-4"
              >
                {t('label:ok')}
              </Button>
            </div>
          </Row>
        </ModalCustom>
      )}
      {errorGetData && (
        <ErrorPopup
          messageError={t('label:message.something_when_wrong')}
          open={errorGetData}
          onCancel={() => setErrorGetData(false)}
        />
      )}
    </>
  );
};

export default CreditAmountCpn;
