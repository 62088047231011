import { CloseOutlined } from '@ant-design/icons';
import { Modal, ModalProps } from 'antd';
import React from 'react';

function ModalCustom(props: ModalProps) {
  return (
    <Modal footer={false} closeIcon={<CloseOutlined className="close-model-icon" />} {...props}>
      {props.children}
    </Modal>
  );
}

export default ModalCustom;
