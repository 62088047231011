/* eslint-disable prettier/prettier */
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Col, Divider, Form, Input, Row } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import CurrencyCodeList from 'src/components/Common/CurrencyCodeList';
import LoginAndRegister from 'src/components/Common/LoginAndRegister';
import ModalCustom from 'src/components/Common/ModalCustom';
import PhoneCodeList from 'src/components/Common/PhoneCodeList';
import { useTypedDispatch } from 'src/hooks/useTypedDispatch';
import { setConfigAxios } from 'src/services/api.services';
import { lineLoginHandle } from 'src/services/util';
import { accountAction } from 'src/stores/auth/auth.actions';
import {
  loginServices,
  RegisterByPhonePayload,
  registerByPhoneServices,
  RegisterPayload,
  registerServices,
  resendOtpServices,
  updateStatusUserServices,
  ValidateAccountPayload,
  validateAccountServices,
} from 'src/stores/auth/auth.service';
import LineIcon from '../../assets/images/line.png';
import { formatPhoneNumber, phoneValidator } from 'src/helpers/utils';
import { countryCodes, REGION_BLOCK_REGISTER_ACC } from 'src/helpers/constanst';

type TypeFormRegister = {
  currencyCode: string;
  mobile: string;
  password: string;
  passwordConfirm: string;
  phoneCode: string;
};

type ErrorType = {
  title: string;
  message: string;
  onCloseCallback?: any;
};

function Register() {
  const history = useHistory();
  const paramSearch = useLocation().search;
  const params = useMemo(() => new URLSearchParams(paramSearch), [paramSearch]);
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  const [form] = Form.useForm();
  const [formValidateOtpCode] = Form.useForm();
  const [dataResendOtp, setDataResendOtp] = useState<{ phoneCode?: string; mobile?: string } | undefined>();
  const [errorModal, setErrorModal] = useState<ErrorType>();
  const [, setLoadingRegister] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [account, setAccount] = useState<RegisterPayload>();

  const phoneDefault = localStorage.getItem('phoneCode') || '60';
  const currencyDefault = countryCodes.find((item) => item.code === phoneDefault);

  useEffect(() => {
    form.setFields([
      {
        name: 'phoneCode',
        value: phoneDefault,
      },
      {
        name: 'currencyCode',
        value: currencyDefault?.currency || 'MYR',
      },
    ]);

    if (params.get('lang')) {
      history.push('/register');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    setIsModalOpen(false);
    setDataResendOtp(undefined);
  };

  const handleCancelError = (callback: any = () => {}) => {
    setErrorModal(undefined);
    callback();
  };

  const registerSubmit = (values: TypeFormRegister) => {
    const country = countryCodes.find((item) => item.code === values.phoneCode);
    const phone = formatPhoneNumber(values.mobile);

    if (country?.country) {
      hanlderRegisterByPhone(
        {
          country: country.country,
          name: `${values.phoneCode}${phone}`,
          phone: `+${values.phoneCode}${phone}`,
        },
        {
          langKey: 'en',
          login: `${values.phoneCode}${phone}`,
          password: values.password,
          paymentCurrency: values.currencyCode,
        },
      );
    }
  };

  const hanlderRegisterByPhone = async (data: RegisterByPhonePayload, dataRes: RegisterPayload) => {
    try {
      setLoadingRegister(true);
      const response: any = await registerByPhoneServices(data);
      if (response && response.data && response.status === 200) {
        // setIsModalOpen(true);
        const registerValues = form.getFieldsValue();
        setDataResendOtp({ phoneCode: registerValues.phoneCode, mobile: registerValues.mobile });
        setAccount(dataRes);
        hanlderRegister(dataRes);
      }
    } catch (error) {
      let message;
      if ((error as any)?.response?.data && (error as any)?.response?.data.message) {
        message = (error as any)?.response?.data.message;
        if (message === 'Mobile number already been taken') {
          message = t('label:auth.number_already_taken');
        }
      } else {
        message = t('label:message.error');
      }
      setLoadingRegister(false);
      setErrorModal({ title: t('label:message.error'), message: message });
    }
  };

  const hanlderRegister = async (data: RegisterPayload) => {
    try {
      await registerServices(data);
      setIsModalOpen(true);
    } catch (error) {
      const errorCode = (error as any)?.response?.data?.code || 0;
      const errorMess = (error as any)?.response?.data?.detail || (error as any)?.message || 'UNKNOWN';
      if (errorCode === 4002) {
        setErrorModal({
          title: t('label:message.info'),
          message: t('label:auth.inactive_register_account'),
          onCloseCallback: () => {
            setIsModalOpen(true);
          },
        });
      } else {
        setErrorModal({ title: t('label:message.error'), message: errorMess });
      }
    }
  };

  const hanlderValidatePhone = (values: any) => {
    if (dataResendOtp?.mobile) {
      const phone = formatPhoneNumber(dataResendOtp.mobile);
      validateOTP({
        username: `${dataResendOtp?.phoneCode || ''}${phone}`,
        verificationCode: values.otpCode,
      });
    }
  };

  const validateOTP = async (payload: ValidateAccountPayload) => {
    await validateAccountServices(payload)
      .then((res) => {
        if (res && res.data && res.status === 200) {
          updateStatusUser();
        }
      })
      .catch((error) => {
        const errorMess = (error as any)?.response?.data?.detail;
        setErrorModal({ title: t('label:message.error'), message: errorMess });
      });
  };

  const updateStatusUser = async () => {
    if (account?.login) {
      await updateStatusUserServices({ login: account?.login })
        .then(handleLogin)
        .catch(() => {
          setErrorModal({ title: t('label:message.error'), message: 'Active status errors' });
        });
    }
  };

  const handlerResendCode = async () => {
    if (dataResendOtp?.mobile && dataResendOtp.phoneCode) {
      await resendOtpServices(`${dataResendOtp.phoneCode}${formatPhoneNumber(dataResendOtp?.mobile)}`)
        .then(() => {
          hanlderResendOTP();
        })
        .catch((error) => {
          const errorMess = (error as any)?.response?.data?.detail;
          setErrorModal({ title: t('label:message.error'), message: errorMess });
        });
    }
  };

  const handleLogin = async () => {
    if (account?.login && account?.password) {
      await loginServices({ username: account?.login, password: account?.password })
        .then((response) => {
          if (response && response.data && response.status === 200) {
            const token = response.data.id_token;
            localStorage.setItem('token', token);
            initAccount(token);
          }
        })
        .catch(() => {
          setIsModalOpen(false);
          setErrorModal({ title: t('label:message.error'), message: 'Cannot login after validate' });
        });
    }
  };

  const initAccount = async (token: string) => {
    try {
      if (token) {
        setConfigAxios(token);
        await dispatch(accountAction(true));
      }
    } catch (error) {}
  };

  //countdown time resend otp
  const refCoundown = useRef<any>(null);
  const defaultTImeCount = 60000;
  const [isHaveSend, setIsHaveSend] = useState<boolean>(false);

  const rendererCountDown = ({ minutes, seconds }: any) => {
    return (
      <span className="text-red-500">
        {minutes}:{seconds}
      </span>
    );
  };

  useEffect(() => {
    if (isModalOpen) {
      hanlderResendOTP();
    } else {
      refCoundown.current?.stop();
    }
  }, [isModalOpen]);

  const coundownMemo = useMemo(() => {
    return (
      <Countdown
        ref={refCoundown}
        date={Date.now() + defaultTImeCount}
        renderer={rendererCountDown}
        onComplete={() => {
          setIsHaveSend(true);
        }}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const hanlderResendOTP = () => {
    refCoundown.current?.start();
    setIsHaveSend(false);
  };

  const changePhoneCodeByCurrencyCode = (val: string) => {
    const code = countryCodes.find((item) => item.currency === val);
    if (code) {
      form.setFieldValue('phoneCode', code.code);
    }
  };

  const changeCurrencyByPhoneCode = (val: string) => {
    const find = countryCodes.find((item) => item.code === val);
    if (find) {
      form.setFieldValue('currencyCode', find.currency);
    }
  };

  const validatePassword = (value: string) => {
    if (value && value.length < 6) {
      return Promise.reject(new Error(t('errors:auth.password_at_least_6_length')));
    }

    return Promise.resolve();
  };

  const validateConfirmPassword = (value: string) => {
    if (!value) {
      return Promise.reject(new Error(t('errors:auth.password_req')));
    }
    const password = form.getFieldValue('password');

    if (password !== value) {
      return Promise.reject(new Error(t('errors:auth.password_confirm_must_map_password')));
    }
    if (value && value.length < 6) {
      return Promise.reject(new Error(t('errors:auth.password_at_least_6_length')));
    }

    return Promise.resolve();
  };

  return (
    <>
      <LoginAndRegister>
        <Row className="d-flex flex-column-reverse flex-md-row">
          <Row className="mb-4 w-100 justify-content-between">
            <Col
              span={3}
              className="d-none d-md-block"
              style={{
                width: '100%',
                borderTop: '6px solid #5e3ea6',
              }}
            />

            <button
              onClick={lineLoginHandle}
              type="button"
              className="btn hstack btn-custom-Line"
              style={{ backgroundColor: '#58bf38' }}
            >
              <img width={30} src={LineIcon} className="me-2 me-md-0" alt="" />
              <div className="vr mx-2 d-none d-md-block"></div>
              <span style={{ color: '#FFFFFF' }}>{t('label:auth.line_register_label').toString()}</span>
            </button>
          </Row>
          <Col span={24} className="mb-4">
            <Form form={form} onFinish={registerSubmit}>
              <Row>
                <Col>
                  <h1 className="text-light mt-4 mt-md-5">{t('label:auth.register_label').toString()}</h1>
                </Col>
              </Row>
              <Row gutter={14}>
                <Col span={8}>
                  <Form.Item
                    name="phoneCode"
                    rules={[
                      {
                        required: true,
                        message: t('errors:auth.code_req').toString(),
                      },
                    ]}
                  >
                    <PhoneCodeList onChange={changeCurrencyByPhoneCode} hideCountry={REGION_BLOCK_REGISTER_ACC} />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item
                    name="mobile"
                    rules={[
                      {
                        validator: (_, value) => phoneValidator(form.getFieldValue('phoneCode'), value),
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      id="mobileNumber"
                      className="form-control form-input-style py-2"
                      placeholder={t('label:auth.mobile_number_label')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="currencyCode"
                    rules={[
                      {
                        required: true,
                        message: t('errors:auth.currency_req').toString(),
                      },
                    ]}
                  >
                    <CurrencyCodeList
                      onChange={changePhoneCodeByCurrencyCode}
                      hideCountry={REGION_BLOCK_REGISTER_ACC}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: t('errors:auth.password_req').toString(),
                      },
                      {
                        validator: (_, value) => validatePassword(value),
                      },
                    ]}
                  >
                    <Input.Password
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined style={{ color: '#ffffff' }} />
                      }
                      type="password"
                      id="password"
                      className="form-control form-input-style py-2 password-input"
                      placeholder={t('label:auth.password_label')}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="passwordConfirm"
                    rules={[
                      {
                        validator: (_, value) => validateConfirmPassword(value),
                      },
                    ]}
                  >
                    <Input.Password
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined style={{ color: '#ffffff' }} />
                      }
                      type="password"
                      id="passwordConfirm"
                      className="form-control form-input-style py-2 password-input"
                      placeholder={t('label:auth.confirm_password_label')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <button type="submit" className="btn fw-bold w-100 login-button py-2 mt-3">
                    <span style={{ color: '#FFFFFF' }}>{t('label:auth.register_label').toLocaleUpperCase()}</span>
                  </button>
                </Col>
                <Col span={24}>
                  <div className="form-text mt-2 py-1 fs-6">
                    <Link to="/login" style={{ color: '#b492ff' }}>
                      <u>{t('label:auth.have_account_login_question').toString()}</u>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </LoginAndRegister>

      <ModalCustom
        title={t('label:auth.validate_phone_number').toString()}
        open={isModalOpen}
        onCancel={handleCancel}
        centered
        className="modal-validate-phone-number"
      >
        <div className="validate-popup-content">
          <p>{t('label:auth.modal_validate_register.note_two').toString()}</p>
          <Form form={formValidateOtpCode} onFinish={hanlderValidatePhone}>
            <Row gutter={16}>
              <Col span={24} md={12}>
                <Form.Item
                  name="otpCode"
                  rules={[
                    {
                      required: true,
                      message: t('errors:auth.code_req') || '',
                    },
                  ]}
                >
                  <input
                    type="text"
                    id="otpCode"
                    className="form-control form-input-style py-2"
                    placeholder={t('label:auth.enter_otp_code') || ''}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <button type="submit" className="btn fw-bold w-100 login-button py-2">
                  <span style={{ color: '#FFFFFF' }}>{t('label:auth.validate').toString()}</span>
                </button>
              </Col>
            </Row>
          </Form>
        </div>

        <Divider style={{ backgroundColor: '#271843', margin: '12px 0px' }} />

        <div className="validate-popup-content">
          {t('label:popup.time').toString()}: {coundownMemo}
          <Form className="mt-2">
            <div className="row gx-3">
              <div className="col-4">
                <Form.Item>
                  <PhoneCodeList disabled value={dataResendOtp?.phoneCode} hideCountry={REGION_BLOCK_REGISTER_ACC} />
                </Form.Item>
              </div>
              <div className="col-8">
                <Form.Item>
                  <input
                    value={dataResendOtp?.mobile}
                    disabled
                    type="text"
                    id="mobileNumber"
                    className="form-control form-input-style py-2"
                    placeholder={t('label:auth.mobile_number_label')}
                  />
                </Form.Item>
              </div>
              <div>
                <button
                  type={'button'}
                  className="btn fw-bold px-5 login-button py-2"
                  style={{
                    backgroundColor: '#26212f',
                    backgroundImage: 'none',
                    cursor: isHaveSend ? 'pointer' : 'not-allowed',
                  }}
                  onClick={() => {
                    isHaveSend && handlerResendCode();
                  }}
                >
                  <span style={{ color: '#FFFFFF' }}>{t('label:auth.resend').toString()}</span>
                </button>
              </div>
            </div>
          </Form>
        </div>
      </ModalCustom>

      <ModalCustom
        title={errorModal?.title}
        open={!!errorModal}
        onCancel={() => {
          handleCancelError(errorModal?.onCloseCallback);
        }}
        centered
      >
        <div className="validate-popup-content">
          <p> {(errorModal?.message, t('label:message.error'))}</p>
        </div>
      </ModalCustom>
    </>
  );
}

export default Register;
