import moment from 'moment';
import i18n from 'src/i18n';
import { countryCodes } from './constanst';

export const formatMoney = (value: any = '', decimalCount = 0, decimal = '.', thousands = ',') => {
  try {
    let amount = value.toString().replace(/,/g, '');
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    const i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    const j = i.length > 3 ? i.length % 3 : 0;
    const valueFormat = Math.abs(amount - parseInt(i))
      .toFixed(decimalCount)
      .slice(2);
    const decimalValue = decimalCount ? decimal + valueFormat : '';
    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      decimalValue
    );
  } catch (e) {
    console.log(e);
  }
};

export const formatTimeToLLL = (time: any) => {
  return moment(time).format('LLL');
};

export const formatTime = (time: any) => {
  return moment(time).format('YYYY-MM-DD HH:mm:ss');
};

export const formatTimeStampToDate = (time: any) => {
  return moment.unix(time).format('YYYY-MM-DD HH:mm:ss');
};

export const formatTimeStampToLLL = (time: any) => {
  return moment.unix(time).format('LLL');
};

export const getKeyMultiLanguageLabelErrorByCode = (code: string) => {
  switch (code.toString()) {
    case '502':
      return 'label:message.response_phone_service';
    case '500':
      return 'errors:error.internalServerError';
    case '4002':
      return 'label:auth.inactive_register_account';
    case '4011':
      return 'errors:error.multiple_login_detech';
    case '4010':
      return 'label:auth.login_error';
    default:
      return 'label:message.something_when_wrong';
  }
};

export const appendZerosToNumber = (amount?: number) => {
  return amount ? amount * 1000 : 0;
};

export const formatNumberWithPoint = (currency: string, amount: number) => {
  const currencyNeedFormat = ['vnd', 'idr'];
  const fAmount = currencyNeedFormat.includes(currency?.toLowerCase()) ? appendZerosToNumber(amount) : amount;
  return fAmount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatPhoneNumber = (phone: string | number) => {
  const fPhone = phone?.toString();
  return fPhone?.charAt(0) === '0' ? fPhone.substring(1) : fPhone;
};

export const phoneValidator = (telCode: string, phone: string) => {
  if (!phone) {
    return Promise.reject(new Error(i18n.t('errors:auth.mobile_number_req')));
  }

  if (phone.charAt(0) === '0') {
    phone = phone.substring(1);
  }

  if (phone.startsWith('casino')) {
    return Promise.resolve();
  }

  if (phone !== '') {
    const getPhonePattern = countryCodes.find((item: any) => item.code === telCode);

    if (!getPhonePattern?.phonePattern?.test(`${telCode}${phone}`)) {
      return Promise.reject(new Error(i18n.t('errors:auth.please_enter_phone_number')));
    }
  }

  return Promise.resolve();
};
