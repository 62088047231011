interface Params {
  [key: string]: string;
}
export const AppRoutes = {
  replaceParams: (route: string, params: Params = {}) => {
    let finalRoute = route;
    Object.keys(params).forEach((key) => {
      finalRoute = finalRoute.replace(`:${key}`, params[key]);
    });
    return finalRoute;
  },
  handoff: '/',
  login: '/login',
  loginExternal: '/external-login-success',
  register: '/register',
  externalRegister: '/external-register',
  reset: '/reset',
  dashboard: '/dashboard',
  lobby: '/lobby',
  admin: '/admin/user-access',
  userDetail: '/admin/user-access-detail/:id',
  userUpdate: '/admin/user-access-update/:id',
  walletEvent: '/admin/user-access/user-wallet-event/:id/:phone',
  bannerSetting: '/admin/banner-setting',
  notFound: '/404',
};
