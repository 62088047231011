import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Select } from 'antd';
import i18n from 'src/i18n';

import DropdownMenu from 'src/components/Common/DropdownMenu';
import BaseButtonPrimary from 'src/components/Common/Buttons/BaseButtonPrimary';
import CreditAmountCpn from 'src/components/Common/CreditAmountCpn';
import DropdownUserManager from 'src/components/Common/DropdownUserManager';

import { logoutServices } from 'src/stores/auth/auth.service';
import { useTypedDispatch } from 'src/hooks/useTypedDispatch';
import { memberWalletAction } from 'src/stores/auth/auth.actions';
import { AppRoutes } from 'src/routes/app.routes';
import { useCurrentUser } from 'src/hooks/useCurrentUser';

import { ArrayLanguage, HEIGHT_BUTTON_HEADER, FLAG_ICON_SIZE } from 'src/helpers/constanst';

import CasinoHackLogo from 'src/assets/images/casinohack-logo.png';

const { Option } = Select;

interface IProps {
  setShowTopupModal: (value: boolean) => void;
  setShowChangePasswordModal: (value: boolean) => void;
}

const Header = ({ setShowTopupModal, setShowChangePasswordModal }: IProps) => {
  const { t } = useTranslation();
  const user = useCurrentUser();
  const dispatch = useTypedDispatch();

  const HandleRefresh = () => {
    dispatch(memberWalletAction());
  };

  const onLogOutHandle = () => {
    Modal.confirm({
      okText: t('label:ok'),
      cancelText: t('label:cancel'),
      title: t('label:app.logout_message'),
      icon: null,
      onOk: async () => {
        await logoutServices();
        localStorage.removeItem('token');
        window.location.href = '/login';
      },
    });
  };

  const onChangeLanguage = (value: string) => {
    localStorage.setItem('language', value);
    i18n.changeLanguage(value);
  };

  const handlerChangePassword = () => {
    setShowChangePasswordModal(true);
  };

  const renderButtonHeader = () => (
    <div className="col d-flex justify-content-start align-items-center">
      <a href={AppRoutes.lobby}>
        <img className="img-fluid" src={CasinoHackLogo} alt="CasinoHack.Club" />
      </a>
    </div>
  );

  return (
    <div id="dashboard-header" className="px-3 d-flex alignt-items-center">
      <div className="row dashboard-center-width m-auto gx-0 flex-wrap h-100 w-100">
        {renderButtonHeader()}
        <div className="col d-flex justify-content-end align-items-center">
          <DropdownUserManager className="d-none d-md-flex" onShowChangePassword={handlerChangePassword} />
          <CreditAmountCpn
            onClick={() => setShowTopupModal(true)}
            onRefresh={(e) => {
              e.stopPropagation();
              HandleRefresh();
            }}
          />
          <Select
            onChange={onChangeLanguage}
            className="form-select me-3 d-none d-md-flex"
            style={{ backgroundColor: '#1d1e20', color: '#e4e4e4', border: 'none', width: '20%' }}
            value={i18n.language}
          >
            {ArrayLanguage.map((item) => {
              return (
                <Option key={item.id} value={item.id}>
                  <img className="me-1" width={FLAG_ICON_SIZE} height={FLAG_ICON_SIZE} src={item.flag} alt="" />
                  {item.label}
                </Option>
              );
            })}
          </Select>
          <BaseButtonPrimary
            title={t('label:app.logout') || ''}
            className="d-none d-md-flex"
            style={{ height: HEIGHT_BUTTON_HEADER, minHeight: 'auto' }}
            prefixDefault="Logout"
            onClick={onLogOutHandle}
          />
          <DropdownMenu user={user} onLogout={onLogOutHandle} onChangePassword={handlerChangePassword} />
        </div>
      </div>
    </div>
  );
};

export default Header;
