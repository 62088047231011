import { CloseCircleOutlined } from '@ant-design/icons';
import { ModalProps } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import CommonButton from 'src/components/Common/CommonButton';
import ModalCustom from 'src/components/Common/ModalCustom';
import { AppRoutes } from 'src/routes/app.routes';

const ErrorPopup = (props: { messageError: string; redirectToLobby?: boolean; hideButton?: boolean } & ModalProps) => {
  const history = useHistory();
  const timeOutRedirect = useRef<any>();
  const RedirectToLobby = () => {
    history.push(AppRoutes.lobby);
  };
  useEffect(() => {
    if (props.open && props.redirectToLobby) {
      clearTimeout(timeOutRedirect.current);
      timeOutRedirect.current = setTimeout(() => {
        RedirectToLobby();
      }, 2000);
    }
    return () => {
      clearTimeout(timeOutRedirect.current);
    };
    // eslint-disable-next-line
  }, [props.open]);

  const onCancel = () => {
    clearTimeout(timeOutRedirect.current);
    props.redirectToLobby && RedirectToLobby();
  };

  return (
    <ModalCustom title="" wrapClassName="popup-custom_one" closeIcon={null} {...props} onCancel={onCancel}>
      <div className="d-flex flex-column justify-content-center text-center pt-5">
        <CloseCircleOutlined className="" style={{ color: '#f27474', fontSize: '4rem' }} />
        <p className="text-light fs-6 py-1">{props.messageError}</p>
        {!props.hideButton && (
          <CommonButton className="btn-setting w-25 mx-auto" onClick={props.onCancel}>
            OK
          </CommonButton>
        )}
      </div>
    </ModalCustom>
  );
};

export default ErrorPopup;
