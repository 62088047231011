import React from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from 'src/components/Common/Wrapper';
import './styles.css';

function PageNotFound() {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div className="not-found-page">
        <span className="title">{t('label:app.access_denied')}</span>
        <span className="note">{t('label:app.access_denied_note')}</span>
      </div>
    </Wrapper>
  );
}

export default PageNotFound;
