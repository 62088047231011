import { useEffect, useState } from 'react';
import { useTypedDispatch } from './useTypedDispatch';
import { AxiosError } from 'axios';
import { setConfigAxios } from 'src/services/api.services';
import { accountAction } from 'src/stores/auth/auth.actions';
import { useTranslation } from 'react-i18next';
import { clearAuthLocalData } from 'src/helpers/auth';

export default function useInitialUserAuthen() {
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useTypedDispatch();
  const { i18n } = useTranslation();

  const initAccount = async () => {
    const token = localStorage.getItem('token');
    const language = localStorage.getItem('language') ?? 'en';
    i18n.changeLanguage(language);
    try {
      if (token) {
        setConfigAxios(token);
        await dispatch(accountAction());
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response && error.response.status === 401) {
          clearAuthLocalData();
        }
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    initAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
  };
}
