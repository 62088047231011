import React from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

const LineGraph = ({ roadMap }: { roadMap: any }) => {
  const { t } = useTranslation();
  const newRoadMap = roadMap.map((item: string) => {
    if (item === '0') return 0;

    if (item === '1') return 1;
    return -1;
  });

  return (
    <Line
      data={{
        labels: Array.from({ length: newRoadMap.length }, (_, i) => i + 1),
        datasets: [
          {
            data: newRoadMap,
            borderColor: '#ffd800',
            fill: false,
          },
        ],
      }}
      options={{
        tooltips: {
          callbacks: {
            label: function (val: any) {
              return null;
            },
          },
        },
        legend: {
          display: false,
        },
        scaleShowVerticalLines: false,
        layout: {
          padding: 25,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: false,
                color: 'gray',
                lineWidth: 1.5,
              },
              ticks: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
                color: 'gray', // line vertical color
                zeroLineColor: 'red', // line of zero color
                zeroLineWidth: 3,
              },
              ticks: {
                beginAtZero: true,
                min: -1,
                max: 1,
                stepSize: 1,
                display: true,
                fontColor: 'white', // changed from 'white'
                callback: function (label: number) {
                  switch (label) {
                    case 0:
                      return t('label:dashboard.tie');
                    case 1:
                      return t('label:dashboard.banker');
                    case -1:
                      return t('label:dashboard.player');
                  }
                  return label;
                },
              },
            },
          ],
        },
      }}
    />
  );
};

export default LineGraph;
