import { Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { LobbyCard, MenuType } from './type';
import { getProviders } from 'src/stores/app/app.service';

interface Props {
  onMenuSelected: (data: MenuType) => void;
  menuSelected: MenuType;
}

const MenuMobileDashboard = (props: Props) => {
  const { menuSelected, onMenuSelected } = props;
  const [listProviders, setListProviders] = useState([]);
  const selectedProvider = LobbyCard.find((p) => p.key === menuSelected);

  useEffect(() => {
    getProviders()
      .then((res: any) => {
        setListProviders(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const itemSelected = LobbyCard.find((item) => {
    return item.key === menuSelected;
  });

  const checkMenu = (menuCode: string) => {
    const find = listProviders.find((provider: any) => provider.provider === menuCode && provider.show);
    return find ? true : false;
  };

  return (
    <Col span={24} className="d-md-none">
      <div className="dashboard-menu-M w-100 d-flex d-md-none">
        {LobbyCard.map(function (item) {
          if (checkMenu(item.key)) {
            return (
              <div
                className={`dashboard-menu-M__item ${menuSelected === item.key ? 'dashboard-menu-M__item-tive' : ''}`}
                key={item.key}
                onClick={() => {
                  onMenuSelected(item.key);
                }}
              >
                <div className="container-icon">
                  <img src={item.icon} alt={item.title} width={40} />
                </div>
                {item.title}
              </div>
            );
          }
          return undefined;
        })}
      </div>
      {itemSelected && (
        <div className="d-flex align-items-center justify-content-center my-2">
          {selectedProvider?.profile && (
            <div className="h-25 w-25">
              <img className="img-fluid" src={selectedProvider.profile} alt={selectedProvider?.title} />
            </div>
          )}
          <div className="ms-1">
            <div>
              <img src={itemSelected.icon} className="me-1" alt={'alt'} width={30} />
              <span className="text-white">{itemSelected.title}</span>
            </div>
            <span className="text-white"> BACCARAT FORMULA</span>
          </div>
        </div>
      )}
    </Col>
  );
};

export default MenuMobileDashboard;
