/* eslint-disable prettier/prettier */
import { apiServices } from 'src/services/api.services';
import { ApiRouters } from 'src/services/api.routers';

type LoginPayload = {
  password: string;
  username: string;
};

export type RegisterByPhonePayload = {
  country: string;
  name: string;
  phone: string;
};

export type RegisterPayload = {
  langKey: string;
  login: string;
  password: string;
  paymentCurrency: string;
};

export type ValidateAccountPayload = {
  username: string;
  verificationCode: string;
};

export type changePasswordAccountPayload = {
  currentPassword: string;
  newPassword: string;
};

export const enum CurrencyCode {
  // CNY = 'CNY',

  IDR = 'IDR',

  // KRW = 'KRW',

  MYR = 'MYR',

  // SGD = 'SGD',

  THB = 'THB',

  // USD = 'USD',

  VND = 'VND',
}

export interface updateStatusUserPayload {
  id?: any;
  login?: string;
  firstName?: string;
  lastName?: string;
  paymentCurrency?: CurrencyCode;
  email?: string;
  activated?: boolean;
  langKey?: string;
  authorities?: string[];
  createdBy?: string;
  createdDate?: Date;
  lastModifiedBy?: string;
  lastModifiedDate?: Date;
  password?: string;
}

export const loginServices = async (data: LoginPayload) => {
  return apiServices.post(ApiRouters.login, data);
};

export const logoutServices = async () => {
  return apiServices.post(ApiRouters.logout);
};

export const accountServices = async () => {
  return apiServices.get(ApiRouters.account);
};

export const memberWallerServices = async () => {
  return apiServices.get(ApiRouters.memberWaller);
};

export const registerServices = async (data: RegisterPayload) => {
  return apiServices.post(ApiRouters.register, data);
};

export const registerByPhoneServices = async (data: RegisterByPhonePayload) => {
  return apiServices.post(ApiRouters.registerByPhone, data);
};

export const validateAccountServices = async (data: ValidateAccountPayload) => {
  return apiServices.post(ApiRouters.validateAccount, data);
};

export const getOtpServices = async (data: any) => {
  return apiServices.post(ApiRouters.getOtp, data);
};

export const validateOtpServices = async (data: any) => {
  return apiServices.post(ApiRouters.validateOtp, data);
};

export const resendOtpServices = async (data: string) => {
  return apiServices.post(ApiRouters.resendOtp, { username: data });
};

export const changePasswordServices = async (data: changePasswordAccountPayload) => {
  return apiServices.post(ApiRouters.changePassword, data);
};

export const forgotPasswordServices = async (username: string) => {
  return apiServices.post(ApiRouters.forgotPassword, { username });
};

export const forgotPasswordVerifyOtpServices = async (username: string, verificationCode: string) => {
  return apiServices.post(ApiRouters.forgotPasswordVerifyOtp, { username, verificationCode });
};

export const updateStatusUserServices = async (payload: updateStatusUserPayload) => {
  return apiServices.post(ApiRouters.updateStatusUser, payload);
};
