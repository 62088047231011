import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useTypedDispatch } from 'src/hooks/useTypedDispatch';
import { AppRoutes } from 'src/routes/app.routes';
import { accountAction } from 'src/stores/auth/auth.actions';

const ExternalLogin = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const dispatch = useTypedDispatch();

  const token = query.get('t') || '';

  if (token) {
    localStorage.setItem('token', token);
    dispatch(accountAction());

    window.open(AppRoutes.lobby, '_self');

    return <div>Redirecting</div>;
  }

  return <Redirect to={AppRoutes.login} />;
};

export default ExternalLogin;
