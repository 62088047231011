import { LeftOutlined, LoadingOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Row, Spin } from 'antd';
import { ButtonHTMLType } from 'antd/lib/button/button';
import React, { CSSProperties } from 'react';
import LogOut from '../../../assets/images/logout.png';
interface PropsType {
  onClick?: (e: any) => void;
  className?: string;
  title?: string;
  prefixIcon?: any;
  suffixIcon?: any;
  prefixDefault?: 'Logout' | 'left' | 'menu';
  style?: CSSProperties;
  loading?: boolean;
  loadingSize?: 'small' | 'default' | 'large';
  htmlType?: ButtonHTMLType;
}

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />;

const BaseButtonPrimary = (props: PropsType) => {
  const { suffixIcon, prefixDefault, prefixIcon, title, style, loading, loadingSize, htmlType } = props;
  const renderPrefixIcon = () => {
    if (prefixIcon) {
      return prefixIcon;
    }
    if (prefixDefault === 'Logout') {
      return <img width={18} height={18} className={`${title ? 'me-1' : ''}`} src={LogOut} alt="" />;
    }
    if (prefixDefault === 'left') {
      return <LeftOutlined style={{ color: 'white' }} className={`${title ? 'me-1' : ''}`} />;
    }
    if (prefixDefault === 'menu') {
      return <MenuOutlined style={{ color: 'white' }} className={`${title ? 'me-1' : ''}`} />;
    }
    return <></>;
  };

  const renderSuffixIcon = () => {
    if (suffixIcon) {
      return prefixIcon;
    }
    return <></>;
  };

  return (
    <Button
      title={props.title}
      className={`d-flex align-items-center justify-content-center flex-nowrap base_button_primary ${
        props.className || ''
      } `}
      onClick={props.onClick}
      style={style}
      htmlType={htmlType || 'button'}
    >
      <Row className="align-items-center justify-content-center flex-nowrap">
        {loading ? (
          <Spin indicator={antIcon} size={loadingSize || 'default'} />
        ) : (
          <>
            {renderPrefixIcon()}
            {title && (
              <span className="fw-bold text-truncate" style={{ color: '#FFFFFF' }}>
                {title}
              </span>
            )}
            {renderSuffixIcon()}
          </>
        )}
      </Row>
    </Button>
  );
};

export default BaseButtonPrimary;
