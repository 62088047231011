import { Col, Form, Row } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import CurrencyCodeList from 'src/components/Common/CurrencyCodeList';
import LoginAndRegister from 'src/components/Common/LoginAndRegister';
import { useTypedDispatch } from 'src/hooks/useTypedDispatch';
import { AppRoutes } from 'src/routes/app.routes';
import { registerByLine } from 'src/stores/app/app.service';
import { accountAction } from 'src/stores/auth/auth.actions';
import ErrorPopup from '../Admin/ErrorPopup';
import { REGION_BLOCK_REGISTER_ACC } from 'src/helpers/constanst';

const ExternalRegister = () => {
  const { t } = useTranslation();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const login = query.get('socialMediaUserId') || '';
  const requestToken = query.get('requestToken') || '';
  const state = query.get('state') || '';
  const dispatch = useTypedDispatch();
  const [hasError, setHasError] = useState(false);
  const [messageError, setMessageError] = useState('');

  const onRegisterHandle = (val: any) => {
    const payload = {
      paymentCurrency: val.currencyCode,
      login,
      requestToken,
      state,
    };

    if (!login || !requestToken || !state) {
      setHasError(true);
      setMessageError(t('label:auth.register_error') || 'Error');
    } else {
      registerByLine(payload)
        .then((res) => {
          const { jwt } = res.data;

          localStorage.setItem('token', jwt);
          dispatch(accountAction());
          window.open(AppRoutes.lobby, '_self');
        })
        .catch((error) => {
          const errorMess = (error as any)?.response?.data?.detail || (error as any)?.message || 'UNKNOW';
          setHasError(true);
          setMessageError(errorMess);
        });
    }
  };

  return (
    <LoginAndRegister>
      <Row className="d-flex flex-column-reverse flex-md-row">
        <Row className="mb-4 w-100 justify-content-between">
          <Col
            span={3}
            className="d-none d-md-block"
            style={{
              width: '100%',
              borderTop: '6px solid #5e3ea6',
            }}
          />
        </Row>
        <Col span={24} className="mb-4">
          <Form onFinish={onRegisterHandle}>
            <Row>
              <Col>
                <h1 className="text-light mt-4 mt-md-5">{t('label:auth.register_label_line').toString()}</h1>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="currencyCode"
                  rules={[
                    {
                      required: true,
                      message: t('errors:auth.currency_req').toString(),
                    },
                  ]}
                >
                  <CurrencyCodeList
                    placeholder={t(`------${t('label:placeholder.please_select')}------`)}
                    hideCountry={REGION_BLOCK_REGISTER_ACC}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <button className="btn fw-bold w-100 login-button py-2 mt-3" type="submit">
                  <span style={{ color: '#FFFFFF' }}>{t('label:auth.register_label').toLocaleUpperCase()}</span>
                </button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <ErrorPopup messageError={messageError} open={hasError} onCancel={() => setHasError(false)} />
    </LoginAndRegister>
  );
};

export default ExternalRegister;
