import { Avatar, Col, Row } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CollapseCard from 'src/components/CollapseCard';
import CreditAmountCpn from 'src/components/Common/CreditAmountCpn';
import DonutChart from 'src/components/Common/DonutChart';
import NoteCreditInfo from 'src/components/Common/NoteCreditInfo';
import SwitchCustom from 'src/components/Common/SwitchCpn/SwitchCustom';
import { useWindowSize } from 'src/hooks/useWindowSize';
import BettingLiveInfo from './BettingLiveInfo';
import LineGraph from './LineGraph';

interface Props {
  title?: string;
  winrate?: number;
  roadMap?: string;
  predictions: any;
  formulaId: any;
  openSound: boolean;
  onChangeSound: (val: boolean) => void;
  showAmountCredit?: boolean;
  checkedSwitch?: boolean;
  onChangeSwitch?: (val: boolean) => void;
  showSwitch?: boolean;
  filterGrayAll?: boolean;
  classNameCollapse?: string;
  hideVolume?: boolean;
  showInfo?: boolean;
  hideNextRound?: boolean;
}

const WIDTH_FOR_MOBILE = 767;

const CardTypeTwo = (props: Props) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const hideExpan = width && width > WIDTH_FOR_MOBILE ? true : false;
  const showLabelExpan = width && width <= WIDTH_FOR_MOBILE ? true : false;
  const {
    roadMapArr,
    nextRound,
    totalTie,
    totalBanker,
    totalPlayer,
    percentTie,
    percentBanker,
    percentPlayer,
  } = useMemo(() => {
    const predictionByFormula = props.predictions.predictionBodyListDTO;
    // const predictionByFormula = props.predictions.predictResultDTOList?.find(
    //   (item: any) => item.formulaId === props.formulaId,
    // );
    let roadMapArr = props.predictions.roadMapArr || [];
    let predictOutcome;
    if (roadMapArr.length > 72) {
      roadMapArr = roadMapArr.slice(roadMapArr.length - 72 - (roadMapArr.length % 6), roadMapArr.length);
      predictOutcome = predictionByFormula.slice(
        roadMapArr.length - 72 - (roadMapArr.length % 6),
        roadMapArr.length + 1,
      );
      // predictOutcome = JSON.parse(predictionByFormula.predictResult).slice(
      //   roadMapArr.length - 70,
      //   roadMapArr.length + 1,
      // );
    } else {
      predictOutcome = predictionByFormula;
    }

    // get nextRound
    const nextRound = predictOutcome[roadMapArr.length];

    //get Live Statistic
    let totalBanker = 0;
    let totalPlayer = 0;
    let totalTie = 0;
    if (roadMapArr.length > 0) {
      roadMapArr.forEach((element: any) => {
        if (element === '2') {
          totalPlayer += 1;
        } else if (element === '1') {
          totalBanker += 1;
        } else if (element === '0') {
          totalTie += 1;
        }
      });
    }

    //calculatePredictionCorrectRate, total round, array predictOutcome
    let totalWin = 0;
    let totalLoss = 0;
    const dataTablePredict = [];
    if (predictOutcome.length) {
      for (let x = 0; x < (roadMapArr.length > 72 ? 72 : roadMapArr.length); x++) {
        dataTablePredict.push(predictOutcome[x]);
        if (
          predictOutcome[x]?.compareResult === 'true' ||
          predictOutcome[x]?.compareResult === 'Calculating' ||
          predictOutcome[x]?.compareResult === 'Return'
        ) {
          totalWin += 1;
        } else {
          // eslint-disable-next-line
          totalLoss += 1;
        }
      }
    }
    const totalRound = roadMapArr.length;

    const percentBanker = Math.round((totalBanker / totalRound) * 100);
    const percentPlayer = Math.round((totalPlayer / totalRound) * 100);
    const percentTie = 100 - percentBanker - percentPlayer;

    return {
      dataTablePredict,
      roadMapArr,
      nextRound,
      totalRound,
      totalWin,
      totalLoss,
      totalTie,
      totalBanker,
      totalPlayer,
      percentTie,
      percentBanker,
      percentPlayer,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.predictions, props.formulaId]);

  const renderHeader = () => {
    return (
      <Row className="align-items-center" gutter={14}>
        <Col>{props.title}</Col>
        <Col className="text-green fw-bold">
          {t('label:dashboard.winRate')} {props.winrate}%
        </Col>
      </Row>
    );
  };

  const renderNextRoundPrediction = () => {
    return (
      <Col span={24} xl={12} className="mb-3">
        <div
          className={`table-card-selected-bg w-100 rounded-3 ${
            !props.filterGrayAll ? 'filter-desktop-gray filter-mobile-gray' : ''
          } `}
        >
          <BettingLiveInfo
            totalBanker={totalBanker}
            totalPlayer={totalPlayer}
            totalTie={totalTie}
            roadMapArr={roadMapArr}
            nextRound={nextRound}
            hideNextRound={props.hideNextRound}
          />
        </div>
      </Col>
    );
  };

  const renderStatisticalGraph = () => {
    return (
      <Col span={24} className="mb-3">
        <div
          className={`table-card-selected-bg w-100 rounded-3 h-100 ${
            !props.filterGrayAll ? 'filter-desktop-gray filter-mobile-gray' : ''
          }`}
        >
          <Row className="">
            <Col className="pt-4 ps-4 d-flex flex-column" span={4}>
              <div className="formula-item-selected text-small fw-semibold">
                {t('label:dashboard.statisticalGraph')}
              </div>
              <div className="text-light text-small mt-auto mb-3"> {t('label:dashboard.Trend')}</div>
            </Col>
            <Col span={20} className="pt-3 line-chart">
              <LineGraph roadMap={roadMapArr} />
            </Col>
          </Row>
        </div>
      </Col>
    );
  };

  const renderPercentageLog = () => {
    const donutChartList = [
      { label: t('label:dashboard.banker'), value: percentBanker, color: '#ff080e' },
      { label: t('label:dashboard.player'), value: percentPlayer, color: '#1692d2' },
      { label: t('label:dashboard.tie'), value: percentTie, color: '#23eb05' },
    ];
    const total = [
      { label: 'B', value: totalBanker, color: 'circle-bg-red' },
      { label: 'P', value: totalPlayer, color: 'circle-bg-blue' },
      { label: 'T', value: totalTie, color: 'circle-bg-green' },
    ];
    return (
      <Col span={24} xl={12} className="mb-3 ">
        <div
          className={`table-card-selected-bg w-100 rounded-3 h-100 ${
            !props.filterGrayAll ? 'filter-desktop-gray filter-mobile-gray' : ''
          }`}
        >
          <p className="pt-4 ps-4 formula-item-selected text-small fw-semibold">{t('label:dashboard.PercentageLog')}</p>
          <Row>
            {donutChartList?.map((item) => (
              <Col className="d-flex justify-content-center" span={8} key={item?.label}>
                <DonutChart
                  value={item?.value}
                  strokeIndicator={item?.color}
                  size={100}
                  strokewidth={8}
                  valuelabel={item?.label}
                />
              </Col>
            ))}
          </Row>
          <Row
            className={`d-flex justify-content-center ${
              !props.filterGrayAll ? 'filter-desktop-gray filter-mobile-gray' : ''
            } `}
          >
            {total?.map((item, key) => (
              <div className="p-2 mt-2" key={key}>
                <Avatar className={`${item?.color} text-small mx-1 fw-bold`} size={25}>
                  {item?.label}
                </Avatar>
                <span className="fw-bold text-light">{item?.value}</span>
              </div>
            ))}
          </Row>
        </div>
      </Col>
    );
  };

  return (
    <div>
      <CollapseCard
        header={renderHeader()}
        showLabelExpan={showLabelExpan}
        isMobile={showLabelExpan}
        onlyOpen={hideExpan}
        hideExpan={hideExpan}
        openSound={props.openSound}
        onPlaySound={props.onChangeSound}
        showAmountCredit={props.showAmountCredit}
        showSwitch={props.showSwitch}
        checkedSwitch={props.checkedSwitch}
        onChangeSwitch={props.onChangeSwitch}
        hideVolume={props.hideVolume}
        className={`${props.classNameCollapse ? props.classNameCollapse : ''}`}
        showInfo={props.showInfo}
      >
        <Row className="d-flex d-md-none align-items-center justify-content-between mb-3">
          <CreditAmountCpn
            className={`ms-0 ${!props.filterGrayAll ? 'filter-desktop-gray filter-mobile-gray' : ''}`}
            hideRefreshCredit={true}
          />
          <Row className="align-items-center">
            <SwitchCustom checked={props.checkedSwitch} onChange={props.onChangeSwitch} />\
            <NoteCreditInfo overlayClassName="d-md-none" trigger={['click']} />
          </Row>
        </Row>
        <Row gutter={15}>
          {renderNextRoundPrediction()}
          {renderPercentageLog()}
          {renderStatisticalGraph()}
        </Row>
      </CollapseCard>
    </div>
  );
};

export default React.memo(CardTypeTwo);
