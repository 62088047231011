import { apiServices } from 'src/services/api.services';
import { ApiRouters } from 'src/services/api.routers';
import moment from 'moment';

export type getWalletHistoryType = {
  page: number;
  size: number;
  userId: number;
};

export type updateUserCreditType = {
  credit: string;
  remark: string;
  userId: number;
};

export const memberCurrencyServices = async () => {
  return apiServices.get(ApiRouters.memberCurrency);
};

export const createAmountSettingServices = async () => {
  return apiServices.get(ApiRouters.createAmountSetting);
};

export const depositsServices = async (payload: any) => {
  return apiServices.post(ApiRouters.deposits, payload);
};

export const getFormulaServices = async () => {
  return apiServices.get(ApiRouters.getFormulas);
};

export const getLineLoginLink = async () => {
  return apiServices.get(ApiRouters.lineLogin);
};

export const getProviders = async () => {
  return apiServices.get(`${ApiRouters.getProviders}?timestamp=${moment().unix()}`);
};

export const setProviders = async (payload: string) => {
  return apiServices.get(`${ApiRouters.setProviders}?providers=${payload}&timestamp=${moment().unix()}`);
};

export const getTableServices = async (provider: string) => {
  return apiServices.get(`${ApiRouters.getTables}/${provider}`);
};

export const getPredictionsServices = async (provider: string, providerTableName: string, formulaName: string) => {
  // return apiServices.get(`${ApiRouters.getPredictions}?providerTableId=${providerTableId}`);
  return apiServices.get(
    `${ApiRouters.getPredictions}?provider=${provider}&providerTableName=${providerTableName}&formulaName=${formulaName}`,
  );
};

export const getMemberLinkServices = async (id: string) => {
  return apiServices.get(`${ApiRouters.getMemberLink}/${id}`);
};

export const getUser = async (id: string) => {
  return apiServices.get(`${ApiRouters.getUser}/${id}`);
};

export const updateUser = async (payload: any) => {
  return apiServices.put(`${ApiRouters.updateUser}`, payload);
};

export const getWalletHistory = async (payload: getWalletHistoryType) => {
  return apiServices.get(
    `${ApiRouters.getWalletHistory}?page=${payload.page}&size=${payload.size}&userId=${payload.userId}`,
  );
};

export const updateUserCreditSevices = async (payload: updateUserCreditType) => {
  return apiServices.put(
    `${ApiRouters.updateUserCredit}?credit=${payload.credit}&remark=${payload.remark}&userId=${payload.userId}`,
    undefined,
  );
};

export const resetPasswordServices = async (payload: any) => {
  return apiServices.put(`${ApiRouters.updateUser}/${payload.id}`, payload);
};

export const registerByLine = async (payload: {
  paymentCurrency?: string;
  state: string;
  login: string;
  requestToken: string;
}) => {
  return apiServices.post(ApiRouters.registerByLine, payload);
};

export const deductCreditServices = async () => {
  return apiServices.get(`${ApiRouters.deductCredit}`);
};
