/* eslint-disable prettier/prettier */
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Col, Form, Input, Row } from 'antd';
import { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ErrorPopup from 'src/components/Common/ErrorPopup';
import LoginAndRegister from 'src/components/Common/LoginAndRegister';
import PhoneCodeList from 'src/components/Common/PhoneCodeList';
import { formatPhoneNumber, getKeyMultiLanguageLabelErrorByCode, phoneValidator } from 'src/helpers/utils';
import { useTypedDispatch } from 'src/hooks/useTypedDispatch';
import { lineLoginHandle } from 'src/services/util';
import { loginAction } from 'src/stores/auth/auth.actions';
import LineIcon from '../../assets/images/line.png';

type LoginFormType = {
  username: string;
  password: string;
  phoneCode: string;
};

function Login() {
  const history = useHistory();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const captchaRef = useRef<any>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isLoginEnable, setLoginEnable] = useState<boolean>(false);
  const paramSearch = useLocation().search;
  const params = useMemo(() => new URLSearchParams(paramSearch), [paramSearch]);
  const [hasError, setHasError] = useState(params.get('errCode') === '4013' || params.get('errCode') === '4011');
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [messageError, setMessageError] = useState<string | undefined>(
    params.get('errCode') === '4013'
      ? t('label:auth.deactivated') ?? 'unknown'
      : params.get('errCode') === '4011'
      ? t('errors:error.multiple_login_detech') ?? 'unknown'
      : 'unknown',
  );

  const dispatch = useTypedDispatch();

  const initValueForm: LoginFormType = {
    username: '',
    password: '',
    phoneCode: localStorage.getItem('phoneCode') || '60',
  };

  const loginSubmit = async () => {
    const loginValues: LoginFormType = form.getFieldsValue();
    const phone = formatPhoneNumber(loginValues.username);
    localStorage.setItem('phoneCode', loginValues.phoneCode);
    try {
      await dispatch(loginAction(loginValues.phoneCode + phone, loginValues.password));
    } catch (error) {
      const errorCode = get(error, 'response.data.code', undefined);
      const errorMsg = !!errorCode
        ? t(getKeyMultiLanguageLabelErrorByCode(errorCode))
        : get(error, 'response.data.message', 'unknown');

      setMessageError(errorMsg);
      setHasError(true);
    }
    setLoading(false);
  };

  const handleChangeUrl = useCallback(() => {
    if (hasError && (params.get('errCode') === '4013' || params.get('errCode') === '4011')) {
      setHasError(false);
      history.push('/login');
    }
  }, [hasError, history, params]);

  if (window.performance) {
    if (performance.navigation.type === 1) {
      handleChangeUrl();
    }
  }

  useEffect(() => {
    if (params.get('lang')) {
      history.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShowCaptcha(true);
    }, 200);
    return () => {
      clearTimeout(timeId);
      handleChangeUrl();
    };
  }, [handleChangeUrl]);

  const validateInputLength = (input: any) => {
    if (input?.value?.length < input?.minLength) {
      const msgTranslate = t('label:message.input_validate', {
        minLength: input?.minLength,
        inputLength: input?.value?.length,
      });
      input.setCustomValidity(msgTranslate);
    } else {
      input.setCustomValidity('');
    }
  };

  const onValidatePassword = () => {
    const input: any = document.getElementById('password');
    input.addEventListener('input', () => {
      validateInputLength(input);
    });
    validateInputLength(input);
  };

  return (
    <LoginAndRegister>
      <Row className="d-flex flex-column-reverse flex-md-row">
        <Row className="mb-4 w-100 justify-content-between">
          <Col
            span={3}
            className="d-none d-md-block"
            style={{
              width: '100%',
              borderTop: '6px solid #5e3ea6',
            }}
          />
          <button
            type="button"
            className="btn hstack btn-custom-Line"
            style={{ backgroundColor: '#58bf38' }}
            onClick={lineLoginHandle}
          >
            <img width={30} src={LineIcon} className="me-2 me-md-0" alt="logo" />
            <div className="vr mx-2 d-none d-md-block"></div>
            <span style={{ color: '#FFFFFF' }}>{t('label:auth.line_login_label')}</span>
          </button>
        </Row>
        <Col>
          <Form<LoginFormType>
            initialValues={initValueForm}
            form={form}
            onFinish={() => {
              setLoading(true);
              loginSubmit();
            }}
            autoComplete={'off'}
          >
            <Row>
              <Col>
                <h1 className="text-light mt-4 mt-md-5">{t('label:auth.log_in_label')}</h1>
              </Col>
            </Row>
            <Row className="mt-2 mt-md-0" gutter={14}>
              <Col span={8}>
                <Form.Item
                  name="phoneCode"
                  rules={[
                    {
                      required: true,
                      message: t('errors:auth.code_req') || 'Code',
                    },
                  ]}
                >
                  <PhoneCodeList />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  name="username"
                  rules={[
                    {
                      validator: (_, value) => phoneValidator(form.getFieldValue('phoneCode'), value),
                    },
                  ]}
                >
                  <Input
                    type="text"
                    id="mobileNumber"
                    autoComplete="off"
                    className="form-control form-input-style py-2"
                    placeholder={t('label:auth.mobile_number_label')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  id="password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t('errors:auth.password_req') || 'Password',
                    },
                  ]}
                >
                  <Input.Password
                    style={{ backgroundColor: '#25202d !important' }}
                    className="form-control form-input-style py-2 password-input"
                    placeholder={t('label:auth.password_label')}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined style={{ color: '#ffffff' }} />
                    }
                    minLength={6}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="form-text">
                  <Link style={{ color: '#8d3ea6' }} to="/reset">
                    {t('label:auth.forgot_password_question')}
                  </Link>
                </div>
              </Col>
              <Col span={24} className="my-3">
                {showCaptcha ? (
                  <ReCAPTCHA
                    className="g-recaptcha"
                    theme="dark"
                    ref={captchaRef}
                    sitekey={process.env.REACT_APP_SITE_KEY || ''}
                    onChange={() => {
                      if (captchaRef?.current?.getValue()) {
                        setLoginEnable(true);
                      }
                    }}
                    onExpired={() => setLoginEnable(false)}
                  />
                ) : null}
              </Col>
            </Row>
            <Row className="my-3">
              <Col span={24}>
                <button
                  type="submit"
                  className="btn fw-bold w-100 login-button py-2"
                  disabled={!isLoginEnable || isLoading}
                  onClick={onValidatePassword}
                >
                  <span style={{ color: '#FFFFFF' }}>{t('label:auth.login_label').toLocaleUpperCase()}</span>
                </button>
              </Col>
              <Col span={24}>
                <div className="form-text mt-3 py-1 fs-6">
                  <Link to="/register" style={{ color: '#b492ff' }}>
                    <u>{t('label:auth.dont_have_account_question')}</u>
                  </Link>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      {hasError && messageError && (
        <ErrorPopup
          messageError={messageError?.toUpperCase()}
          messageDes={
            params.get('errCode') === '4011' ? t('errors:error.multiple_login_detech_description') : undefined
          }
          open={hasError}
          onCancel={() => setHasError(false)}
          className="popup-login-error ws-pre-wrap"
        />
      )}
    </LoginAndRegister>
  );
}

export default Login;
