import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Col, Form, Input, notification, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatMoney } from 'src/helpers/utils';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { useCurrentUserWallet } from 'src/hooks/useCurrentUserWallet';
import { useTypedDispatch } from 'src/hooks/useTypedDispatch';
import { memberCurrencyAction } from 'src/stores/app/app.actions';
import { changePasswordServices } from 'src/stores/auth/auth.service';
import BaseButtonPrimary from '../Common/Buttons/BaseButtonPrimary';
import ModalCustom from '../Common/ModalCustom';
type Props = {
  visible: boolean;
  onChangeVisible: (visible: boolean) => void;
  onVisibleTopup: (visible: boolean) => void;
};

const ChagnePasswordModal = ({ visible, onChangeVisible, onVisibleTopup }: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [changePassError, setChangePassError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const currentUser = useCurrentUser();
  const wallet = useCurrentUserWallet();

  const dispatch = useTypedDispatch();

  const getData = () => {
    dispatch(memberCurrencyAction());
  };
  useEffect(() => {
    if (visible) {
      getData();
    }
    // eslint-disable-next-line
  }, [visible]);

  const validateConfirmPassword = (value: string) => {
    if (!value) {
      return Promise.reject(new Error(t('errors:auth.password_req')));
    }
    const password = form.getFieldValue('newPassword');
    if (password !== value) {
      return Promise.reject(new Error(t('errors:auth.password_confirm_must_map_password')));
    }
    if (value && value.length < 6) {
      return Promise.reject(new Error(t('errors:auth.password_at_least_6_length')));
    }

    return Promise.resolve();
  };

  const validatePassword = (value: string) => {
    if (value && value.length < 6) {
      return Promise.reject(new Error(t('errors:auth.password_at_least_6_length')));
    }

    return Promise.resolve();
  };

  const handlerChangePassword = async (values: any) => {
    setChangePassError(undefined);
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    await changePasswordServices({
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
    })
      .then((res) => {
        if (res && res.status === 200) {
          notification.success({
            message: t('label:message.success').toString(),
            description: t('label:message.change_password_success').toString(),
          });
          form.resetFields();
          onChangeVisible(false);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.title === 'Incorrect password') {
          notification.error({
            message: t('label:message.error').toString(),
            description: t('errors:auth.current_password_invalid').toString(),
          });
        } else {
          notification.error({
            message: t('label:message.error').toString(),
            description: t('label:message.something_when_wrong').toString(),
          });
        }
      });

    setIsLoading(false);
  };

  const cancelModal = () => {
    onChangeVisible(false);
    setChangePassError(undefined);
    form.resetFields();
  };

  return (
    <ModalCustom
      title={t('label:auth.change_password')}
      open={visible}
      onCancel={cancelModal}
      wrapClassName="popup-custom_one"
    >
      <Col>
        <Row className="flex-col flex-sm-row flex-wrap" gutter={14}>
          <Row className="justify-content-between label">
            <Col>{t('label:popup.member_id').toString()} :</Col>
            <Col className="label ms-2">{currentUser?.login}</Col>
          </Row>
        </Row>
        <Row className="flex-col flex-sm-row flex-wrap mt-2 label" gutter={14}>
          <Row className="justify-content-between label">
            <Col>{t('label:popup.credit').toString()} :</Col>
            <Col className="label ms-2">
              {formatMoney(wallet?.credit, 2)}{' '}
              <span
                className="topup_credit ms-2"
                onClick={() => {
                  onChangeVisible(false);
                  onVisibleTopup(true);
                }}
              >
                {t('label:popup.top_up_credit').toLocaleUpperCase()}
              </span>
            </Col>
          </Row>
        </Row>
        <Form form={form} className="mt-4" onFinish={handlerChangePassword}>
          <Col span={24}>
            <Form.Item
              name={'currentPassword'}
              rules={[
                {
                  required: true,
                  message: t('errors:auth.password_req').toString(),
                },
                {
                  validator: (_, value) => validatePassword(value),
                },
              ]}
            >
              <Input.Password
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined style={{ color: '#ffffff' }} />
                }
                type="password"
                id="password"
                className="form-control form-input-style py-2 password-input"
                placeholder={t('label:auth.currentPassword')}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: t('errors:auth.password_req').toString(),
                },
                {
                  validator: (_, value) => validatePassword(value),
                },
              ]}
            >
              <Input.Password
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined style={{ color: '#ffffff' }} />
                }
                type="password"
                id="password"
                className="form-control form-input-style py-2 password-input"
                placeholder={t('label:auth.new_password')}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="passwordConfirm"
              rules={[
                {
                  validator: (_, value) => validateConfirmPassword(value),
                },
              ]}
            >
              <Input.Password
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined style={{ color: '#ffffff' }} />
                }
                type="password"
                id="password"
                className="form-control form-input-style py-2 password-input"
                placeholder={t('label:auth.confirm_password_label')}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <BaseButtonPrimary
              className="w-100"
              title={t('label:app.confirm').toLocaleUpperCase()}
              loading={isLoading}
              htmlType="submit"
            />
            {changePassError && <p className="error">{changePassError}</p>}
          </Col>
        </Form>
      </Col>
    </ModalCustom>
  );
};

export default ChagnePasswordModal;
