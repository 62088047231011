import { Button, Col, ModalProps, notification, Radio, Row, Skeleton, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalCustom from 'src/components/Common/ModalCustom';
import { formatTime } from 'src/helpers/utils';
import { getUser, updateUser } from 'src/stores/app/app.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';

const UpdateMemberPopup = (
  props: { member?: any; onCancelModal?: () => void; onSuccessUpdate?: () => void } & ModalProps,
) => {
  const { t } = useTranslation();
  const [user, setUser] = useState<any>();
  const [activated, setActivated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingGetUser, setLoadingUser] = useState<boolean>(false);
  useEffect(() => {
    if (props.open && props.member?.phone) {
      setLoadingUser(true);
      getUser(props.member.login)
        .then((data) => {
          if (data?.data) {
            setUser(data.data);
            setActivated(data.data.activated);
          }
        })
        .finally(() => {
          setLoadingUser(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.member?.phone, props.open]);

  const handlerUpdateUser = async () => {
    if (user && props.member.phone) {
      setLoading(true);
      await updateUser({ ...user, activated: activated })
        .then(() => {
          props.onSuccessUpdate && props.onSuccessUpdate();
          props.onCancelModal && props.onCancelModal();
        })
        .catch(() => {
          notification.error({
            message: t('label:message.error'),
            description: t('label:message.something_when_wrong'),
          });
        });
      setLoading(false);
    }
  };

  const hanlderChangeActivated = (val: any) => {
    setActivated(val.target.value);
  };

  return (
    <ModalCustom title="" wrapClassName="popup-custom_one" closeIcon={null} {...props} onCancel={props.onCancelModal}>
      <Col span={24} className="update-member-container p-3 pt-4">
        <Skeleton loading={loadingGetUser}>
          {user && (
            <>
              <p className="title-page mb-3">{t('label:user.info.updateMember')}</p>
              <p className="title">{t('label:user.info.id')}</p>
              <p className="value mb-2">{user.id}</p>
              <p className="title">{t('label:user.info.login')}</p>
              <p className="value mb-2">{user.login}</p>
              <Radio.Group className="activated mb-2" onChange={hanlderChangeActivated} value={activated}>
                <Space direction="vertical">
                  <Radio className="activated-item" value={true}>
                    {t('label:user.info.activate')}
                  </Radio>
                  <Radio className="activated-item" value={false}>
                    {t('label:user.info.deactivate')}
                  </Radio>
                </Space>
              </Radio.Group>
              <p className="title">{t('label:user.info.createdDate')}</p>
              <p className="value mb-2">{formatTime(user.createdDate)}</p>
              <p className="title">{t('label:user.info.lastModifiedDate')}</p>
              <p className="value mb-2"> {formatTime(user.lastModifiedDate)}</p>
              <p className="title">{t('label:user.info.lastModifiedBy')}</p>
              <p className="value mb-2">{user.lastModifiedBy}</p>
              <Row className="align-items-center mt-3" gutter={18}>
                <Col>
                  <Button className="btn-actions btn-actions__back" onClick={props.onCancelModal}>
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon={faBan} className="d-lg-flex me-1" /> {t('label:btn.cancel')}
                    </div>
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="btn-actions btn-actions__edit d-flex"
                    loading={loading}
                    onClick={handlerUpdateUser}
                  >
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon={faFloppyDisk} className="d-lg-flex me-1" /> {t('label:btn.save')}
                    </div>
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Skeleton>
      </Col>
    </ModalCustom>
  );
};

export default UpdateMemberPopup;
