import { Col, Form, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatMoney, formatNumberWithPoint } from 'src/helpers/utils';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { useCurrentUserWallet } from 'src/hooks/useCurrentUserWallet';
import { useTypedDispatch } from 'src/hooks/useTypedDispatch';
import { useTypedSelector } from 'src/hooks/useTypedSelector';
import { OpenWindowWithPost } from 'src/services/util';
import { createAmountSettingAction, memberCurrencyAction } from 'src/stores/app/app.actions';
import { depositsServices } from 'src/stores/app/app.service';
import CommonButton from '../Common/CommonButton';
import ModalCustom from '../Common/ModalCustom';
import { listBank } from 'src/helpers/constanst';
import { MemberCurrencyEntity } from 'src/stores/app/Modal';
import { CreditAmountEntity } from 'src/stores/auth/model';

const { Option } = Select;

type Props = {
  visible: boolean;
  onChangeVisible: (visible: boolean) => void;
  onOpenClaimCredits: (status: boolean) => void;
};

const TopupModal = ({ visible, onChangeVisible, onOpenClaimCredits }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();
  const user = useCurrentUser();
  const wallet = useCurrentUserWallet();
  const [selectedMemberCurrency, setSelectedMemberCurrency] = useState();
  const [bankName, setBank] = useState();
  const [amountIndex, setAmountIndex] = useState<number>();
  const dispatch = useTypedDispatch();
  const memberCurrency: MemberCurrencyEntity | null | undefined = useTypedSelector((state) => state.app.memberCurrency);
  const amountSetting: CreditAmountEntity[] = useTypedSelector((state) => state.app.amountSetting);

  const generateListMemberCurrency = () => {
    if (Array.isArray(memberCurrency)) {
      return memberCurrency;
    }
    return [memberCurrency];
  };

  const listMemberCurrency = generateListMemberCurrency();

  const onOk = async () => {
    const method = listMemberCurrency;

    if (amountIndex !== null && amountIndex !== undefined && bankName && method && method.length) {
      const amountSeleted: CreditAmountEntity | undefined = amountSetting.find((item) => item.id === amountIndex);

      const payload = {
        amount: amountSeleted?.amount,
        credit: amountSeleted?.credit,
        bank: bankName,
        bankId: memberCurrency?.id,
        methodCode: method[0].name,
        returnUrl: process.env.REACT_APP_TOPUP_CALLBACK,
      };

      const depositResponse: any = await depositsServices(payload);
      const params = [
        {
          name: 'partner_code',
          value: depositResponse.data['secretCode'],
        },
        {
          name: 'currency',
          value: depositResponse.data['currency'],
        },
        {
          name: 'trans_time',
          value: depositResponse.data['transactionDateTime'],
        },
        {
          name: 'member_ip',
          value: depositResponse.data['memberIp'],
        },
        {
          name: 'bank_code',
          value: depositResponse.data['bankCode'],
        },
        {
          name: 'amount',
          value: depositResponse.data['amount'],
        },
        {
          name: 'credit',
          value: depositResponse.data['credit'],
        },
        {
          name: 'backend_url',
          value: depositResponse.data['responseUrl'],
        },
        {
          name: 'redirect_url',
          value: depositResponse.data['redirectUrl'],
        },
        {
          name: 'sign',
          value: depositResponse.data['hashKey'],
        },
        {
          name: 'member_id',
          value: depositResponse.data['memberId'],
        },
        {
          name: 'service_version',
          value: depositResponse.data['version'],
        },
        {
          name: 'partner_orderid',
          value: depositResponse.data['requestId'],
        },
      ];

      OpenWindowWithPost(depositResponse.data.depositPostUrl, '_self', params);
    }
  };

  const onClose = () => {
    setBank(undefined);
    setAmountIndex(undefined);
    setSelectedMemberCurrency(undefined);
    onChangeVisible(false);
  };

  const getData = () => {
    dispatch(memberCurrencyAction());
    dispatch(createAmountSettingAction());
  };
  useEffect(() => {
    if (visible) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const generateListAmount = () => {
    return amountSetting.filter((item) => item.currency === user?.paymentCurrency);
  };

  const banks = memberCurrency?.currency ? listBank[memberCurrency?.currency] : [];

  return (
    <ModalCustom
      title={t('label:popup.top_up_credit')}
      open={visible}
      onOk={() => onOk()}
      onCancel={onClose}
      wrapClassName="popup-custom_one"
    >
      <Col>
        <Row className="flex-col flex-sm-row flex-wrap">
          <Row className="justify-content-between label">
            <Col>{t('label:topup.member_id')} :</Col>
            <Col className="label ms-2">{user?.login}</Col>
          </Row>
        </Row>
        <Row className="flex-col flex-sm-row flex-wrap mt-2 label">
          <Row className="justify-content-between label">
            <Col>{t('label:admin.credit')} :</Col>
            <Col className="label ms-2">{formatMoney(wallet?.credit, 2)}</Col>
          </Row>
        </Row>
      </Col>
      <Col className="mt-4">
        <Form>
          <Form.Item>
            <Select
              value={selectedMemberCurrency}
              onChange={setSelectedMemberCurrency}
              className="py-1"
              placeholder={`-- ${t('label:placeholder.please_select_method')} --`}
            >
              {memberCurrency &&
                listMemberCurrency?.map((item: any) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Select
              value={bankName}
              onChange={(value) => setBank(value)}
              className="py-1"
              placeholder={`-- ${t('label:placeholder.please_select_bank')} --`}
            >
              {banks.map((bank: any) => {
                const { code, name } = bank;
                return (
                  <Option key={code} value={code}>
                    {name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Select
              value={amountIndex}
              onChange={(value) => setAmountIndex(value)}
              className="py-1"
              placeholder={`-- ${t('label:placeholder.please_select_amount')} --`}
            >
              {amountSetting &&
                generateListAmount().map((amount: any) => {
                  const { currency, credit, amount: amt, id } = amount;
                  return (
                    <Option key={credit} value={id}>
                      {`${currency} ${formatNumberWithPoint(currency, amt)} - ${credit}  ${t('label:topup.credit')}`}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form>
        <Row className="flex-col flex-sm-row flex-wrap mb-4">
          <Col className="claim-credits-link" onClick={() => onOpenClaimCredits(true)}>
            {t('label:popup.claim_free_credits')}
          </Col>
        </Row>
        <CommonButton onClick={onOk} className="text-white fw-bold w-100 my-2">
          {t('label:btn.confirm')}
        </CommonButton>
      </Col>
    </ModalCustom>
  );
};

export default TopupModal;
