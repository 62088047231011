import { createSlice } from '@reduxjs/toolkit';
import { MemberCurrencyEntity } from './Modal';

type AppState = {
  memberCurrency: MemberCurrencyEntity | undefined | null;
  amountSetting: any;
};

const initialState: AppState = {
  memberCurrency: undefined,
  amountSetting: undefined,
};
export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    memberCurrencyActionSlice: (state, action) => {
      state.memberCurrency = action.payload;
    },
    mountSettingActionSlice: (state, action) => {
      state.amountSetting = action.payload;
    },
  },
});

export const { memberCurrencyActionSlice, mountSettingActionSlice } = appSlice.actions;
