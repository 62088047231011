import React from 'react';

const DonutChart = (props: {
  value: number;
  valuelabel?: string;
  size?: number;
  strokewidth?: number;
  strokeIndicator?: string;
}) => {
  const { size = 116, strokewidth = 15, value = 0, valuelabel = '', strokeIndicator } = props;
  const halfsize = size * 0.5;
  const radius = halfsize - strokewidth * 0.5;
  const circumference = 2 * Math.PI * radius;
  const strokeval = (value * circumference) / 100;
  const dashval = strokeval + ' ' + circumference;

  const trackstyle = { strokeWidth: strokewidth };
  const indicatorstyle = {
    strokeWidth: strokewidth,
    strokeDasharray: dashval,
    stroke: strokeIndicator,
  };
  const rotateval = 'rotate(-90 ' + halfsize + ',' + halfsize + ')';

  return (
    <svg width={size} height={size} className="donutchart">
      <circle
        r={radius}
        cx={halfsize}
        cy={halfsize}
        transform={rotateval}
        style={trackstyle}
        className="donutchart-track"
      />
      <circle
        r={radius}
        cx={halfsize}
        cy={halfsize}
        transform={rotateval}
        style={indicatorstyle}
        className="donutchart-indicator"
      />
      <text className="donutchart-text" x={halfsize} y={halfsize} style={{ textAnchor: 'middle' }}>
        <tspan className="donutchart-text-val fw-bold">{value}</tspan>
        <tspan className="donutchart-text-percent fw-bold">%</tspan>
        <tspan className="donutchart-text-label fw-bold text-uppercase" x={halfsize} y={halfsize + 20}>
          {valuelabel}
        </tspan>
      </text>
    </svg>
  );
};

export default DonutChart;
