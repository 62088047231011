import React from 'react';
import Lobby from 'src/assets/images/lobby-bg.jpg';

const Wrapper = (props: any) => {
  return <div className="wrapper-common">{props.children}</div>;
};

const WrapperLobby = (props: any) => {
  return (
    <div
      className="wrapper-common"
      style={{
        backgroundImage: `url("${Lobby}")`,
      }}
    >
      {props.children}
    </div>
  );
};

export { Wrapper, WrapperLobby };
