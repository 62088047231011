import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { CountryProps, countryVisible } from './PhoneCodeList';

const { Option } = Select;

function CurrencyCodeList<ValueType>(props: CountryProps<ValueType>) {
  const { t } = useTranslation();
  const { hideCountry } = props || [];
  const countryCode = countryVisible(hideCountry);

  return (
    <Select className="form-input-style py-1" placeholder={t('label:auth.currentcy_code_label')} {...props}>
      {countryCode.map((item) => {
        return (
          <Option key={item.currency} value={item.currency}>
            {item.currency}
          </Option>
        );
      })}
    </Select>
  );
}

export default CurrencyCodeList;
