import { Button, Col, Row, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import DashboardLayout from 'src/components/Common/DashboardLayout';
import { AppRoutes } from 'src/routes/app.routes';
import { getUser, updateUser } from 'src/stores/app/app.service';
import ErrorPopup from '../../../components/Common/ErrorPopup';
import SuccessPopup from '../SuccessPopup';
import ResetPasswordPopup from './ResetPasswordPopup';
import { formatTime } from 'src/helpers/utils';
import UpdateUserCreditPopup from './UpdateUserCreditPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPencil, faUnlockKeyhole, faCoins } from '@fortawesome/free-solid-svg-icons';
import UpdateMemberPopup from '../UpdateMember/UpdateMemberPopup';
import CommonSelect from 'src/components/Common/CommonSelect';
import DropdownIcon from 'src/assets/images/dropdown-icon.svg';

type MemberType = {
  activated?: boolean;
  createdDate: string;
  id: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  login: string;
  phone: string;
  paymentAllowed?: boolean;
};

const InfoMember = () => {
  const { t } = useTranslation();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const phone = query.get('phone') || '';
  const params = useParams<any>();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<MemberType>();
  const login = user?.login || '';
  const [errorGetData, setErrorGetData] = useState<boolean>(false);
  const [openPopupCredit, setOpenPopupCredit] = useState<boolean>(false);
  const [openResetPassword, setOpenResetPassword] = useState<boolean>(false);
  const [openUpdateMemberPopup, setOpenUpdateMemberPopup] = useState<boolean>(false);
  const [messageSucces, setMessageSuccess] = useState<string>();
  const [depositStatus, setDepositStatus] = useState<boolean>(false);

  useEffect(() => {
    if (params?.id) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  const getData = () => {
    setLoading(true);

    getUser(params.id)
      .then((res) => {
        const { data } = res;
        if (data) {
          setUser(data);
          setDepositStatus(!!data?.paymentAllowed);
        }
      })
      .catch(() => {
        setErrorGetData(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const hanlderBack = () => {
    history.push(`${AppRoutes.admin}?login=${login}`);
  };

  const goToPageWallet = () => {
    if (user) {
      history.push(`/admin/user-access/user-wallet-event/${user.id}/${user.login}?phone=${phone}`);
    }
  };

  const handlerEdit = () => {
    setOpenUpdateMemberPopup(true);
  };

  const handlerSuccessUpdateMember = () => {
    getData();
  };

  const handlerCancelUpdateMember = () => {
    setOpenUpdateMemberPopup(false);
  };

  const callBackResetSuccess = (newPass: string) => {
    const translateMsg = t('label:admin.NewPassMsg', { newPass });
    setMessageSuccess(translateMsg);
  };

  const handleDepositStatusChange = async (value: string) => {
    const fValue = value.toLowerCase() === 'true';
    setDepositStatus(fValue);
    await updateUser({ ...user, paymentAllowed: fValue })
      .then((res) => {
        if (!!res?.data?.paymentAllowed !== fValue) {
          setDepositStatus(!!res?.data?.paymentAllowed);
        }
      })
      .catch(() => {
        setErrorGetData(true);
      });
  };

  const generateInfo = () => {
    const depositStatusOption = [
      {
        value: 'true',
        label: t('label:btn.yes'),
      },
      {
        value: 'false',
        label: t('label:btn.no'),
      },
    ];

    const infoMember = [
      {
        label: t('label:user.info.login'),
        info: user?.login,
      },
      {
        label: t('label:user.info.phone'),
        info: user?.phone,
      },
      {
        label: t('label:user.info.activated'),
        info: user?.activated ? t('label:btn.yes') : t('label:btn.no'),
      },
      {
        label: t('label:user.info.createdDate'),
        info: formatTime(user?.createdDate),
      },
      {
        label: t('label:user.info.lastModifiedDate'),
        info: formatTime(user?.lastModifiedDate),
      },
      {
        label: t('label:user.info.lastModifiedBy'),
        info: user?.lastModifiedBy,
      },
      {
        label: t('label:user.info.walletEvent'),
        info: (
          <span className="btn-click-event" onClick={goToPageWallet}>
            {t('label:btn.clickHere')}
          </span>
        ),
      },
      {
        label: <span className="fs-6">{t('label:user.info.allow_deposit')}</span>,
        info: (
          <CommonSelect
            value={depositStatus ? t('label:btn.yes') : t('label:btn.no')}
            onChange={handleDepositStatusChange}
            options={depositStatusOption}
            className="w-75 allow-deposit-field form-input_select-container fs-6 fw-bold text-white p-0 lh-1 text-uppercase"
            popupClassName="form-input_select-container__dropdown"
            suffixIcon={<img role="button" className="dropdown-icon" src={DropdownIcon} alt="dropdown icon" />}
          />
        ),
      },
    ];

    return infoMember.map((item, key) => (
      <Row className="member-infor__table-row" key={key}>
        <Col span={12} className="member-infor__table-col left align-items-center">
          {item?.label}
        </Col>
        <Col span={12} className={`member-infor__table-col right align-items-center`}>
          {item?.info}
        </Col>
      </Row>
    ));
  };

  return (
    <DashboardLayout>
      <div className="dashboard-center-width m-auto h-100 ">
        <Row className="justify-content-center background-admin mt-5 py-4">
          <Col span={24} lg={12} className="member-infor p-3">
            <Skeleton loading={loading}>
              {user && (
                <>
                  <Col className="member-infor__title mb-2">{`${t('label:user.member')} ${user.id}`}</Col>
                  {generateInfo()}
                  <Row className="align-items-center d-flex justify-content-start justify-content-sm-between mt-3 ">
                    <Button className="btn-actions btn-actions__back mt-2 me-1 me-sm-0" onClick={hanlderBack}>
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faArrowLeft} className="d-none d-lg-flex me-1" /> {t('label:btn.back')}
                      </div>
                    </Button>

                    <Button className="btn-actions btn-actions__edit mt-2 me-1 me-sm-0" onClick={handlerEdit}>
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faPencil} className="d-none d-lg-flex me-1" /> {t('label:btn.edit')}
                      </div>
                    </Button>

                    <Button
                      className="btn-actions btn-actions__reset-password mt-2 me-1 me-sm-0"
                      onClick={() => {
                        user && setOpenResetPassword(true);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faUnlockKeyhole} className="d-none d-lg-flex me-1" />
                        {t('label:btn.resetPassword')}
                      </div>
                    </Button>

                    <Button
                      className="btn-actions btn-actions__add-credit mt-2"
                      onClick={() => user && setOpenPopupCredit(true)}
                    >
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faCoins} className="d-none d-lg-flex me-1" />
                        {t('label:btn.addCredit')}
                      </div>
                    </Button>
                  </Row>
                </>
              )}
            </Skeleton>
          </Col>
        </Row>
      </div>
      {/* <TopupModal visible={openPopupCredit} onChangeVisible={() => setOpenPopupCredit(false)} /> */}
      <ResetPasswordPopup
        open={openResetPassword}
        user={user}
        onCancelModal={() => setOpenResetPassword(false)}
        resetSucces={callBackResetSuccess}
      />
      <SuccessPopup
        open={!!messageSucces}
        messageSuccess={messageSucces || ''}
        onCancel={() => {
          setMessageSuccess('');
        }}
      />
      <UpdateUserCreditPopup
        open={openPopupCredit}
        member={user}
        onCancelModal={() => user && setOpenPopupCredit(false)}
      />
      <UpdateMemberPopup
        open={openUpdateMemberPopup}
        member={user}
        onCancelModal={handlerCancelUpdateMember}
        onSuccessUpdate={handlerSuccessUpdateMember}
      />
      <ErrorPopup messageError={t('label:message.something_when_wrong')} open={errorGetData} onCancel={hanlderBack} />
    </DashboardLayout>
  );
};

export default InfoMember;
