import React, { Suspense } from 'react';
import DashboardLoader from './components/DashboardLoader';
import { RootRouter } from './routes/App';
import useUnActiveSystem from './hooks/useUnActiveSystem';
import useInitialUserAuthen from './hooks/useInitialUserAuthen';

const App = React.memo(() => {
  useUnActiveSystem();
  const { loading } = useInitialUserAuthen();

  if (loading) return <DashboardLoader />;

  return (
    <Suspense fallback={<DashboardLoader />}>
      <RootRouter />
    </Suspense>
  );
});

export default App;
