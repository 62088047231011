/* eslint-disable import/no-anonymous-default-export */
import flatten from 'flat';
import label from './label.json';
import errors from './errors.json';

export default {
  label: flatten<Record<string, any>, typeof label>(label, {
    delimiter: '_',
  }),
  errors: flatten<Record<string, any>, typeof errors>(errors, {
    delimiter: '_',
  }),
};
