import { Col, Popover, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import annoucementIcon from 'src/assets/images/annoucement-bg.png';
import infoIcon from 'src/assets/images/info_icon.svg';

const NoteCreditInfo = (props: {
  hidedefaultpadding?: boolean;
  className?: string;
  trigger?: Array<string>;
  classNameContent?: string;
  overlayClassName?: string;
}) => {
  const { t } = useTranslation();

  const content = (
    <div className={`note-credit-content ${props.classNameContent ? props.classNameContent : ''}`}>
      <Row className="align-items-center">
        <Col className="me-2">
          <img src={annoucementIcon} width={45} height={45} alt="" />
        </Col>
        <Col>
          <span className="fw-bold">{t('label:dashboard.note_credit_one')}</span>
        </Col>
      </Row>
      <span>{t('label:dashboard.note_credit_two')}</span>
    </div>
  );

  return (
    <div className={`ms-2 ${props.className ? props.className : ''}`}>
      <Popover
        placement="bottomRight"
        content={content}
        trigger={props.trigger ? props.trigger : ['hover']}
        overlayClassName={`note-credit-popover ${props.overlayClassName ? props.overlayClassName : ''}`}
        defaultOpen={true}
      >
        <img src={infoIcon} width={25} height={25} alt="" />
      </Popover>
    </div>
  );
};

export default NoteCreditInfo;
