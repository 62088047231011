import { CheckCircleOutlined } from '@ant-design/icons';
import { ModalProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CommonButton from 'src/components/Common/CommonButton';
import ModalCustom from 'src/components/Common/ModalCustom';

const SuccessPopup = (props: { messageSuccess: string } & ModalProps) => {
  const { t } = useTranslation();
  return (
    <ModalCustom title="" wrapClassName="popup-custom_one" closeIcon={null} {...props}>
      <div className="d-flex flex-column justify-content-center text-center pt-5">
        <CheckCircleOutlined className="" style={{ color: '#a5dc86', fontSize: '4rem' }} />
        <p className="text-light fs-6 py-2">{props.messageSuccess}</p>
        <CommonButton className="btn-setting w-50 mx-auto" onClick={props.onCancel}>
          {t('label:ok')}
        </CommonButton>
      </div>
    </ModalCustom>
  );
};

export default SuccessPopup;
