import { Avatar, Col, Row } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import './style.css';

enum RoadType {
  BANKER = '1',
  PLAYER = '2',
  TIE = '0',
}

enum RoundType {
  BANKER = 'Banker',
  PLAYER = 'Player',
  TIE = 'Tie',
  CAL = 'Calculating',
}
interface Props {
  totalBanker: number;
  totalPlayer: number;
  totalTie: number;
  roadMapArr: string[];
  nextRound?: any;
  hitRate?: number;
  hideNextRound?: boolean;
}

const BettingLiveInfo = (props: Props) => {
  const { t } = useTranslation();
  const { totalBanker, totalPlayer, roadMapArr, totalTie, nextRound, hitRate } = props;
  const convertNewRoadmap = () => {
    const emptyBox =
      roadMapArr.length < 72 ? 72 - roadMapArr.length : roadMapArr.length % 6 === 0 ? 0 : 6 - (roadMapArr.length % 6);
    const newRoadmap = [...roadMapArr];
    if (emptyBox > 0) {
      for (let i = 0; i < emptyBox; i++) {
        newRoadmap.push('3');
      }
    }
    return newRoadmap;
  };

  const newRoadmap = useMemo(() => {
    return convertNewRoadmap();
    // eslint-disable-next-line
  }, [roadMapArr]);

  const nextRoundPredictionView = (id: string, name: string, color: string, cls: string) => {
    return (
      <div className="prediction-circle prediction-sec-bg d-flex align-items-center justify-content-center">
        <Avatar className={`${color} text-small fw-bold me-2`} size={35}>
          {id}
        </Avatar>
        <div className={`name ${cls}`}>{name}</div>
      </div>
    );
  };

  const renderNextRoundPrediction = (round: RoundType) => {
    if (props.hideNextRound) {
      return (
        <div className="prediction-circle prediction-sec-bg d-flex align-items-center justify-content-center">
          <div className="hide-next-round mx-1">{t('label:dashboard.Prediction_active_message')}</div>
        </div>
      );
    }
    if (round === RoundType.BANKER) {
      return nextRoundPredictionView('B', t('label:dashboard.banker'), 'circle-bg-red', 'name-banker');
    }
    if (round === RoundType.PLAYER) {
      return nextRoundPredictionView('P', t('label:dashboard.player'), 'circle-bg-blue', 'name-player');
    }
    if (round === RoundType.TIE) {
      return nextRoundPredictionView('T', t('label:dashboard.tie'), 'circle-bg-green', 'name-tie');
    }
    if (round === RoundType.CAL) {
      return nextRoundPredictionView('W', t('label:dashboard.tie'), 'circle-bg-orange', 'name-cal');
    }
  };

  const renderStatistic = () => {
    return (
      <Row className="prediction-sec__statistic align-items-center justify-content-center mt-3 mb-2 mb-md-3">
        <Col>
          <Row className="prediction-sec__statistic-item__banker align-items-center">
            <Avatar className="circle-bg-red text-small fw-bold me-1" size={23}>
              B
            </Avatar>
            <span className="value text-white">{totalBanker}</span>
          </Row>
        </Col>
        <Col className="mx-2 mx-sm-3">
          <Row className="prediction-sec__statistic-item__player align-items-center">
            <Avatar className="circle-bg-blue text-small fw-bold me-1" size={23}>
              P
            </Avatar>
            <span className="value text-white">{totalPlayer}</span>
          </Row>
        </Col>
        <Col>
          <Row className="prediction-sec__statistic-item__tie align-items-center">
            <Avatar className="circle-bg-green text-small fw-bold me-1" size={23}>
              T
            </Avatar>
            <span className="value text-white">{totalTie}</span>
          </Row>
        </Col>
      </Row>
    );
  };

  const ItemLive = (props: any) => {
    if (props.round === RoadType.BANKER) {
      return (
        <div className="live-result-item d-flex align-items-center">
          <Avatar className="circle-bg-red fw-bold" size={15}>
            <span> B</span>
          </Avatar>
        </div>
      );
    }
    if (props.round === RoadType.PLAYER) {
      return (
        <div className="live-result-item d-flex align-items-center">
          <Avatar className="circle-bg-blue fw-bold" size={15}>
            <span>P</span>
          </Avatar>
        </div>
      );
    }
    if (props.round === RoadType.TIE) {
      return (
        <div className="live-result-item d-flex align-items-center">
          <Avatar className="circle-bg-green fw-bold" size={15}>
            <span> T</span>
          </Avatar>
        </div>
      );
    }
    return (
      <div className="live-result-item">
        <div className="non"></div>
      </div>
    );
  };

  return (
    <Col className="prediction-sec px-4 pt-4" span={24}>
      <span className="formula-item-selected text-small fw-semibold">{t('label:dashboard.nextRoundPrediction')}</span>
      <Row gutter={12} className="mt-3">
        <Col span={24} sm={11} md={10} lg={10}>
          {renderNextRoundPrediction(nextRound?.predictOutcome)}
          {renderStatistic()}
          {hitRate && (
            <span className="hit-rate text-white">{`${t('label:dashboard.hitRate')}: ${(hitRate * 100).toFixed(
              0,
            )}%`}</span>
          )}
        </Col>
        <Col span={24} sm={13} md={14} lg={14}>
          <Col className="live-resul-table">
            {newRoadmap.map((item, index) => {
              return <ItemLive key={index} round={item} />;
            })}
          </Col>
        </Col>
      </Row>
    </Col>
  );
};

export default React.memo(BettingLiveInfo);
