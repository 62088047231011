export const ApiRouters = {
  login: '/api/authenticate',
  logout: '/api/logout',
  account: '/api/account',
  memberWaller: '/api/wallets/memberWallet',
  register: '/api/register',
  registerByPhone: '/api/public/register-by-phone',
  registerByLine: '/api/public/social-oauth/register',
  validateAccount: '/api/public/validate-account',
  getOtp: '/api/public/get-otp',
  validateOtp: '/api/public/validate-otp',
  resendOtp: '/api/public/resend-otp',
  memberCurrency: '/api/banks/memberCurrency',
  changePassword: '/api/account/change-password',
  createAmountSetting: '/api/credit-amount-settings',
  deposits: '/api/deposits/third-party-pay',
  getFormulas: '/api/formulas',
  getTables: '/api/provider-tables/custom',
  getPredictions: '/api/public/get-predictions',
  getMemberLink: '/api/members/memberLink',
  getUser: '/api/users',
  updateUser: '/api/users',
  getProviders: '/public/html/providerSetting.json',
  getWalletHistory: '/api/wallet-events/history',
  setProviders: '/api/game-providers/providerSetting',
  updateUserCredit: '/api/wallets/updateUserCredit',
  resetPassword: '/api/users',
  lineLogin: '/api/public/social-oauth/external-register-login-settings?provider=LINE&action=login',
  deductCredit: '/api/wallets/deductWallet',
  forgotPassword: '/api/public/forgot-password',
  forgotPasswordVerifyOtp: '/api/public/verify-otp',
  updateStatusUser: '/api/update-status',
};
