import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import React, { useState } from 'react';

const { Dragger } = Upload;

const UploadComponent = (propsCpn: any) => {
  const [img, setImg] = useState<string>();
  const uploadImage = async (options: any) => {
    const { onSuccess } = options;
    setImg('src/assets/images/banner.png');
    onSuccess('Ok');
  };

  return (
    <div className={`w-100 h-100 ${propsCpn.className}`}>
      <Dragger name="file" multiple={true} customRequest={uploadImage} showUploadList={false}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
        </p>
      </Dragger>
      {img && <img src={img} alt="banner" />}
    </div>
  );
};

export default UploadComponent;
