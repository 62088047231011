import { AppRoutes } from 'src/routes/app.routes';
import { AppThunk } from '..';
import { accountServices, loginServices, memberWallerServices } from './auth.service';
import { authActionSlice, UserRole, walletActionSlice, selectedRoleUserSlice } from './auth.slice';

export const loginAction = (username: string, password: string): AppThunk => async (dispatch) => {
  const response: any = await loginServices({ username, password });
  if (response && response.data && response.status === 200) {
    const token = response.data.id_token;
    localStorage.setItem('token', token);
    window.location.reload();
  }
  return response;
};

export const accountAction = (isLogin?: boolean): AppThunk => async (dispatch) => {
  const response: any = await accountServices();
  if (response && response.data && response.status === 200) {
    dispatch(memberWalletAction());
    dispatch(authActionSlice(response.data));
    if (isLogin) {
      window.location.replace(AppRoutes.lobby);
    }
  }

  return response;
};

export const memberWalletAction = (): AppThunk => async (dispatch) => {
  const response: any = await memberWallerServices();
  if (response && response.data && response.status === 200) {
    dispatch(walletActionSlice(response.data));
  }
  return response;
};

export const selectUserRoleAction = (role: UserRole): AppThunk => async (dispatch) => {
  dispatch(selectedRoleUserSlice(role));
};
