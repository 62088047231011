import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DashboardLayout from 'src/components/Common/DashboardLayout';
import UploadComponent from './UploadComponent';

export enum PositionBanner {
  LOBBY_TOP_DESKTOP = 'LOBBY_TOP_DESKTOP', //page  LOBBY,
  LOBBY_TOP_MOBILE = 'LOBBY_TOP_MOBILE',
  LOBBY_BOTTOM_MOBILE = 'LOBBY_BOTTOM_MOBILE',
  LOBBY_BOTTOM_DESTOP = 'LOBBY_BOTTOM_DESTOP',
  DASHBOARD_BOTTOM_DESKTOP = 'DASHBOARD_BOTTOM_DESKTOP',
  DASHBOARD_BOTTOM_MOBILE = 'DASHBOARD_BOTTOM_MOBILE',
  DASHBOARD_SIDE_LEFT_DESKTOP = 'DASHBOARD_SIDE_LEFT_DESKTOP',
}

const BannerSetting = () => {
  const { t } = useTranslation();

  return (
    <DashboardLayout>
      <div className="dashboard-center-width m-auto h-100 background-admin my-5 p-4">
        <Col span={24} className="admin-card-cpn pt-2 pb-5 px-4">
          <Row>
            <h2 className="my-3 text-orange fs-3">{t('label:admin.banner_setting')}</h2>
          </Row>
          <span className="my-3 text-orange fs-4"> Lobby Top Desktop</span>
          <UploadComponent className="mt-2" />
          <span className="my-3 text-orange fs-4">Lobby Top Mobile</span>
          <UploadComponent className="mt-2" />
          <span className="my-3 text-orange fs-4">Lobby Bottom Desktop</span>
          <UploadComponent className="mt-2" />
          <span className="my-3 text-orange fs-4">Lobby Bottom Mobile</span>
          <UploadComponent className="mt-2" />
          <span className="my-3 text-orange fs-4"> Dashboard Top Desktop</span>
          <UploadComponent className="mt-2" />
          <span className="my-3 text-orange fs-4">Dashboard Top Mobile</span>
          <UploadComponent className="mt-2" />
          <span className="my-3 text-orange fs-4">Dashboard Bottom Desktop</span>
          <UploadComponent className="mt-2" />
          <span className="my-3 text-orange fs-4">Dashboard Bottom Mobile</span>
          <UploadComponent className="mt-2" />
          <span className="my-3 text-orange fs-4">Dashboard Side Left Desktop</span>
          <UploadComponent className="mt-2" />
          <span className="my-3 text-orange fs-4">Dashboard Side Left Mobile</span>
          <UploadComponent className="mt-2" />
        </Col>
      </div>
    </DashboardLayout>
  );
};

export default BannerSetting;
