import React from 'react';
import { useTranslation } from 'react-i18next';
import CollapseCard, { CollapseCardProps } from 'src/components/CollapseCard';
import { useWindowSize } from 'src/hooks/useWindowSize';

interface Props {
  children?: any;
  className?: string;
}

const WIDTH_FOR_MOBILE = 767;

const CardTypeOne = (props: Props & CollapseCardProps) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const showLabelExpan = width && width <= WIDTH_FOR_MOBILE ? true : false;
  const onlyOpen = width && width > WIDTH_FOR_MOBILE ? true : false;
  return (
    <div>
      <CollapseCard
        header={t('label:dashboard.title.choose_table')}
        hideVolume
        showLabelExpan={showLabelExpan}
        isMobile={showLabelExpan}
        onlyOpen={onlyOpen}
        hideExpan={onlyOpen}
        className={props.className}
        {...props}
      >
        {props.children}
      </CollapseCard>
    </div>
  );
};

export default CardTypeOne;
