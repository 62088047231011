import { Button, Col, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import DashboardLayout from 'src/components/Common/DashboardLayout';
import { formatTime } from 'src/helpers/utils';
import { getWalletHistory } from 'src/stores/app/app.service';
import './style.css';

const PAGE_SIZE = 5;

const WalletEvent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<any>();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const phone = query.get('phone') || '';
  const [page, setPage] = useState<number>(1);
  const [walletHistory, setWalletHistory] = useState<any>();
  const [loadingHistory, setLoadingHistory] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  useEffect(() => {
    if (params?.id) {
      setLoadingHistory(true);
      getWalletHistory({ page: page - 1, size: PAGE_SIZE, userId: params?.id })
        .then((res) => {
          if (res?.data) {
            setWalletHistory(res.data);
            setTotalCount(Number(res.headers['x-total-count'] || 0));
          }
        })
        .finally(() => {
          setLoadingHistory(false);
        });
    }
  }, [params?.id, page]);

  const columns: any = [
    {
      title: t('label:admin.event_time'),
      dataIndex: 'eventTime',
      key: 'eventTime',
      align: 'center',
      render: (_: any, record: any) => {
        return formatTime(_);
      },
    },
    {
      title: t('label:admin.amount'),
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
    },
    {
      title: t('label:admin.credit'),
      dataIndex: 'topupCredit',
      key: 'topupCredit',
      align: 'center',
    },
    {
      title: t('label:admin.credit_before'),
      dataIndex: 'creditBefore',
      key: 'creditBefore',
      align: 'center',
    },
    {
      title: t('label:admin.credit_after'),
      dataIndex: 'creditAfter',
      key: 'creditAfter',
      align: 'center',
    },
    {
      title: t('label:admin.fund_type'),
      dataIndex: 'fund',
      key: 'fund',
      align: 'center',
    },
    {
      title: t('label:admin.source_type'),
      dataIndex: 'source',
      key: 'source',
      align: 'center',
    },
    {
      title: t('label:admin.remark'),
      dataIndex: 'remark',
      key: 'remark',
      align: 'center',
    },
    {
      title: t('label:admin.created_date'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      align: 'center',
      render: (_: any, record: any) => {
        return formatTime(_);
      },
    },
    {
      title: t('label:admin.last_modified_date'),
      dataIndex: 'lastModifiedDate',
      key: 'lastModifiedDate',
      align: 'center',
      render: (_: any, record: any) => {
        return formatTime(_);
      },
    },
    {
      title: t('label:admin.last_modified_by'),
      dataIndex: 'lastModifiedBy',
      key: 'lastModifiedBy',
      align: 'center',
    },
  ];

  const handlerBack = () => {
    if (params?.phone) {
      history.push(`/admin/user-access-detail/${params?.phone}?phone=${phone}`);
    }
  };

  const calcTotalPage = () => {
    if (walletHistory) {
      return Math.ceil(totalCount / PAGE_SIZE);
    }
    return 0;
  };

  const handlerFirstPage = () => {
    if (page === 1) return;
    setPage(1);
  };

  const handlerPrevPage = () => {
    if (page < 2) return;
    setPage(page - 1);
  };

  const handlerNextPage = () => {
    if (calcTotalPage() <= page) return;
    setPage(page + 1);
  };

  const handlerLastPage = () => {
    if (calcTotalPage() <= page) return;
    setPage(calcTotalPage());
  };

  return (
    <DashboardLayout>
      <div className="dashboard-center-width m-auto h-100">
        <div className="page-wallet-event  background-admin pt-1 pb-4 py-md-4 px-3 my-md-5">
          <Row className="justify-content-center mt-4">
            <span className="title">{`${params?.phone}'s ${t('label:user.info.walletEvent')}`}</span>
          </Row>
          <Row className="justify-content-end mt-3">
            <Button className="btn-edit fw-bold" onClick={handlerBack}>
              {t('label:btn.back')}
            </Button>
          </Row>
          <Col className="mt-3" span={24}>
            <Table
              loading={loadingHistory}
              className="table-wallet-event"
              scroll={{ x: 980 }}
              dataSource={walletHistory}
              columns={columns}
              pagination={{ pageSize: 5 }}
              rowClassName="text-white"
            />
            <Row
              className="custom-pagination justify-content-between justify-content-sm-center align-items-center mt-4 px-2"
              gutter={{ sm: 12 }}
            >
              <Col>
                <Button disabled={page === 1} className="btn-view fw-bold" onClick={handlerFirstPage}>
                  {t('label:btn.first')}
                </Button>
              </Col>
              <Col>
                <Button disabled={page < 2} className="btn-view fw-bold" onClick={handlerPrevPage}>
                  {t('label:btn.prev')}
                </Button>
              </Col>
              <Col className="page">
                <span className="d-none d-md-block">{`${t('label:btn.page')} ${page} ${t(
                  'label:btn.of',
                )} ${calcTotalPage()}`}</span>
                <span className="d-md-none">{`${page} / ${calcTotalPage()}`} </span>
              </Col>
              <Col>
                <Button disabled={calcTotalPage() <= page} className="btn-view fw-bold" onClick={handlerNextPage}>
                  {t('label:btn.next')}
                </Button>
              </Col>
              <Col>
                <Button disabled={calcTotalPage() <= page} className="btn-view fw-bold" onClick={handlerLastPage}>
                  {t('label:btn.last')}
                </Button>
              </Col>
            </Row>
          </Col>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default WalletEvent;
