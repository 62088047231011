import React, { ReactNode, useState } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import ChangePasswordModal from 'src/components/ChangePassword';
import TopupModal from 'src/components/TopUp';
import ClaimCreditsPopup from 'src/components/ClaimCreditsPopup';
import Header from 'src/components/Common/Header';

import AECasino from 'src/assets/images/aecasino.png';
import AllBet from 'src/assets/images/allbet.png';
import AEGaming from 'src/assets/images/asiagaming.png';
import DreamGaming from 'src/assets/images/dreamgaming.png';
import Evolution from 'src/assets/images/evolution.png';
import GamePlay from 'src/assets/images/gameplay.png';
import SAGaming from 'src/assets/images/sagaming.png';
import WmCasino from 'src/assets/images/wmcasino.png';
import BigGamingLogo from 'src/assets/images/Big-Gaming-logo.png';
import EbetLogo from 'src/assets/images/Ebet-logo.png';
import TelegramInfo from 'src/components/Common/TelegramInfo';

interface DashboardLayoutProps {
  children: ReactNode;
}

const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  const { t } = useTranslation();

  const [showTopupModal, setShowTopupModal] = useState<boolean>(false);
  const [showClaimCreditsPopup, setShowClaimCreditsPopup] = useState<boolean>(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState<boolean>(false);
  const currentYear = new Date().getFullYear();

  return (
    <Col id="dashboard-common">
      <Header setShowTopupModal={setShowTopupModal} setShowChangePasswordModal={setShowChangePasswordModal} />
      <div id="telegram-header" className="px-3 d-flex align-items-center">
        <div className="row dashboard-center-width m-auto gx-0 flex-wrap h-100 w-100">
          <div className="d-flex align-items-center justify-content-end align-items-center">
            <label className="fs-6 me-3 d-none d-sm-block">{t('label:connect_us')}:</label>
            <TelegramInfo />
          </div>
        </div>
      </div>
      <div id="dashboard-body" className="mt-2">
        {children}
      </div>
      <div id="dashboard-footer" className="px-3">
        <div className="py-4 m-auto dashboard-center-width text-center text-sm-start">
          <Row gutter={32} className="mx-0 align-items-center">
            <Col span={6} md={3}>
              <img className="img-fluid" src={Evolution} alt="Evolution Gaming" />
            </Col>
            <Col span={6} md={3}>
              <img className="img-fluid" src={AECasino} alt="AE Casino" />
            </Col>
            <Col span={6} md={3}>
              <img className="img-fluid" src={AEGaming} alt="AE Gaming" />
            </Col>
            <Col span={6} md={3}>
              <img className="img-fluid" src={GamePlay} alt="GamePlay" />
            </Col>
            <Col span={6} md={3}>
              <img className="img-fluid" src={SAGaming} alt="SA Gaming" />
            </Col>
            <Col span={6} md={3}>
              <img className="img-fluid" src={AllBet} alt="AllBet" />
            </Col>
            <Col span={6} md={3}>
              <img className="img-fluid" src={DreamGaming} alt="Dream Gaming" />
            </Col>
            <Col span={6} md={3}>
              <img className="img-fluid" src={WmCasino} alt="WmCasino" />
            </Col>
            <Col span={6} md={3}>
              <img className="img-fluid" src={BigGamingLogo} alt="Dream Gaming" />
            </Col>
            <Col span={6} md={3}>
              <img className="img-fluid" src={EbetLogo} alt="WmCasino" />
            </Col>
          </Row>
          <Row gutter={32} className="mx-0">
            <Col>
              <p className="mt-2" style={{ color: '#433952' }}>
                {t('label:app.copyright')} {currentYear} {t('label:app.copyright_des')}
              </p>
            </Col>
          </Row>
        </div>
      </div>
      <TopupModal
        visible={showTopupModal}
        onChangeVisible={() => setShowTopupModal(false)}
        onOpenClaimCredits={setShowClaimCreditsPopup}
      />
      <ClaimCreditsPopup visible={showClaimCreditsPopup} onChangeVisible={() => setShowClaimCreditsPopup(false)} />
      <ChangePasswordModal
        visible={showChangePasswordModal}
        onChangeVisible={setShowChangePasswordModal}
        onVisibleTopup={() => setShowTopupModal(true)}
      />
    </Col>
  );
};

export default DashboardLayout;
