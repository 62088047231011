import { Col, Form, ModalProps, notification, Row, Select } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommonButton from 'src/components/Common/CommonButton';
import ModalCustom from 'src/components/Common/ModalCustom';
import { updateUserCreditSevices } from 'src/stores/app/app.service';

const UpdateUserCreditPopup = (props: { member?: any; onCancelModal?: () => void } & ModalProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);

  const ArrayOptionCredit = [
    { value: 10, label: '10 Credit' },
    { value: 20, label: '20 Credit' },
    { value: 40, label: '40 Credit' },
    { value: 100, label: '100 Credit' },
  ];

  const handlerSubmit = async (val: any) => {
    if (props.member) {
      setLoadingUpdate(true);
      await updateUserCreditSevices({ credit: val.credit, remark: val.remark, userId: props.member.id })
        .then(() => {
          notification.success({
            message: t('label:message.success'),
            description: t('label:notifycation.update_succes'),
          });
          setLoadingUpdate(false);
          handlerCancel();
        })
        .catch(() => {
          notification.error({
            message: t('label:message.error'),
            description: t('label:message.something_when_wrong'),
          });
        });
      setLoadingUpdate(false);
    }
  };

  const requiredRule = [
    {
      required: true,
      message: t('errors:this_field_is_req'),
    },
  ];

  const handlerCancel = () => {
    form.resetFields();
    props.onCancelModal && props.onCancelModal();
  };

  return (
    <ModalCustom title="" wrapClassName="popup-custom_one" closeIcon={null} {...props} onCancel={handlerCancel}>
      <Col className="p-2 p-md-3">
        <Col className="text-white d-flex flex-column align-items-center pb-3">
          {props.member && (
            <>
              <span className="mb-2 fs-6">{`${t('label:user.add_credit_to')} ${props.member?.phone}`}</span>
              <span className="fs-6"> {t('label:user.select_the_credit_option_below')}</span>
            </>
          )}
        </Col>
        <Col>
          <Form form={form} onFinish={handlerSubmit}>
            <Form.Item name={'credit'} required rules={requiredRule}>
              <Select options={ArrayOptionCredit} placeholder={t('label:placeholder.please_select')} />
            </Form.Item>
            <Form.Item name="remark" required rules={requiredRule}>
              <input className="form-control form-input-style" placeholder={t('label:user.remarks')} />
            </Form.Item>
          </Form>
        </Col>
        <Row gutter={18}>
          <Col span={12}>
            <CommonButton
              disabled={loadingUpdate}
              className="btn-setting w-100 mx-auto py-2"
              onClick={() => {
                form.submit();
              }}
            >
              {t('label:btn.confirm')}
            </CommonButton>
          </Col>
          <Col span={12}>
            <CommonButton className="btn-edit w-100 mx-auto py-2" onClick={handlerCancel}>
              {t('label:btn.cancel')}
            </CommonButton>
          </Col>
        </Row>
      </Col>
    </ModalCustom>
  );
};

export default UpdateUserCreditPopup;
