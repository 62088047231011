import { Select, SelectProps } from 'antd';
import React from 'react';

function CommonSelect<ValueType>(props: SelectProps<ValueType>) {
  return (
    <Select className={`form-input-style py-1 ${props.className || undefined}`} {...props}>
      {props.children}
    </Select>
  );
}

export default CommonSelect;
