import { Input } from 'antd';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce, filter, orderBy } from 'lodash';

import CommonSelect from 'src/components/Common/CommonSelect';
import Search from '../../assets/images/search.svg';
import DropdownIcon from '../../assets/images/dropdown-icon.svg';

export enum sortKeys {
  NameAToZ = 'name_a_to_z',
  NameZToA = 'name_z_to_a',
  WinRateHighToLow = 'win_rate_high_to_low',
  WinRateLowToHigh = 'win_rate_low_to_high',
}

interface Props {
  sortedItem: sortKeys;
  searchValue: string;
  onSortChange: (value: sortKeys) => void;
  onSearchChange: (value: string) => void;
}

interface FmlDataProps {
  tableCode: string;
  currentRoadMapArr: Array<string>;
  newRoadMapArr?: Array<string>;
  predictionBodyListDTO?: any;
  tableName: string;
  deductedCredit?: boolean;
}

export const filterFmlData = (data: FmlDataProps[], sortedItem: sortKeys, searchValue: string) => {
  const sortItemMaping = {
    [sortKeys.NameAToZ]: [['tableName'], ['asc']],
    [sortKeys.NameZToA]: [['tableName'], ['desc']],
    [sortKeys.WinRateHighToLow]: [['winRate'], ['desc']],
    [sortKeys.WinRateLowToHigh]: [['winRate'], ['asc']],
  };

  const sorted = sortItemMaping[sortedItem];
  // search data
  const fSearchValue = searchValue?.trim()?.toLowerCase();
  const sData = !!fSearchValue
    ? filter(data, (item: FmlDataProps) => {
        const fTableName = item?.tableName?.trim()?.toLowerCase();
        return fTableName.includes(fSearchValue);
      })
    : data;
  // sort data
  return orderBy(sData, ...sorted);
};

const FilterBar = (props: Props) => {
  const { onSortChange, onSearchChange, sortedItem, searchValue } = props;
  const { t } = useTranslation();

  const sortList = [
    {
      value: sortKeys.WinRateHighToLow,
      label: t('label:dashboard.sort.win_rate_high_to_low'),
    },
    {
      value: sortKeys.WinRateLowToHigh,
      label: t('label:dashboard.sort.win_rate_low_to_high'),
    },
    {
      value: sortKeys.NameAToZ,
      label: t('label:dashboard.sort.name_a_to_z'),
    },
    {
      value: sortKeys.NameZToA,
      label: t('label:dashboard.sort.name_z_to_a'),
    },
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((evt) => {
      const { value } = evt.target;
      onSearchChange(value?.trim());
    }, 500),
    [],
  );

  const handleSortChange = (value: sortKeys) => {
    onSortChange(value);
  };

  return (
    <div className="d-flex w-100 mb-3 filter-container">
      <div className="d-flex align-items-baseline">
        <label className="search-text-color w-max-content">{t('label:dashboard.sort_by')}: </label>
        <CommonSelect
          value={sortedItem}
          onChange={handleSortChange}
          options={sortList}
          className=" me-3 form-input_select-container"
          popupClassName="form-input_select-container__dropdown"
          suffixIcon={<img role="button" className="dropdown-icon" src={DropdownIcon} alt="dropdown icon" />}
        />
      </div>

      <div className="w-100 d-flex justify-content-end ps-2">
        <div className="d-flex rounded-1 search-field">
          <Input
            placeholder={t('label:dashboard.search')}
            className="input-search w-100 search-text-color"
            defaultValue={searchValue}
            onChange={handleSearch}
          />
          <img role="button" className="icon-search" src={Search} alt="search icon" />
        </div>
      </div>
    </div>
  );
};

export default React.memo(FilterBar);
