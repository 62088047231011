import React from 'react';
import { ButtonProps } from 'react-bootstrap';

const CommonButton = (props: { hidedefaultpadding?: boolean } & ButtonProps) => {
  return (
    <button
      {...props}
      className={`btn login-button h-75 d-flex align-items-center justify-content-center ${
        props.hidedefaultpadding ? '' : 'px-4'
      } ${props.className}`}
      aria-disabled
    >
      {props.children}
    </button>
  );
};

export default CommonButton;
