import { EditOutlined, EyeOutlined, SettingOutlined } from '@ant-design/icons';
import { Col, Input, Row } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import CommonButton from 'src/components/Common/CommonButton';
import DashboardLayout from 'src/components/Common/DashboardLayout';
import { getMemberLinkServices } from 'src/stores/app/app.service';
import ErrorPopup from '../../components/Common/ErrorPopup';
import ProviderSetting from './ProviderSetting';
import UpdateMemberPopup from './UpdateMember/UpdateMemberPopup';

const { Search } = Input;

function AdminPage() {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [member, setMember] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [providerSetting, setProviderSetting] = useState(false);
  const [phoneSearch, setPhoneSearch] = useState<string | undefined>();
  const [openUpdateMember, setOpenUpdateMember] = useState<boolean>(false);

  useEffect(() => {
    if (location?.search) {
      const login = location.search.split('?login=')[1];
      if (login) setPhoneSearch(login);
    }
  }, [location?.search]);

  useEffect(() => {
    if (phoneSearch) {
      onSearchMemberHandle(phoneSearch);
    }
  }, [phoneSearch]);

  const columns: ColumnsType<any> = [
    {
      title: t('label:user.info.id'),
      key: 'id',
      dataIndex: 'id',
      align: 'left',
      width: 15,
    },
    {
      title: t('label:user.info.login'),
      dataIndex: 'login',
      key: 'login',
      align: 'left',
      width: 100,
    },
    {
      title: t('label:user.info.phone'),
      dataIndex: 'phone',
      key: 'phone',
      align: 'left',
      width: 100,
    },
    {
      title: `${t('label:user.info.activated')}?`,
      render(value, record, index) {
        return value?.activated ? t('label:btn.yes') : t('label:btn.no');
      },
      width: 100,
      key: 'activated',
      align: 'left',
    },
    {
      render(value, record, index) {
        return (
          <Row className="d-flex flex-row justify-content-end flex-nowrap">
            <CommonButton
              className="btn-view rounded-0 rounded-start"
              hidedefaultpadding={true}
              onClick={() => {
                if (record?.login) {
                  history.push(`/admin/user-access-detail/${record.login}?phone=${record.phone}`);
                }
              }}
            >
              <EyeOutlined className="me-0 me-lg-2" />
              <span className="d-none d-lg-block">{t('label:btn.view')}</span>
            </CommonButton>
            <CommonButton
              hidedefaultpadding={true}
              className="btn-edit rounded-0 rounded-end"
              onClick={() => {
                if (record?.phone) {
                  setOpenUpdateMember(true);
                }
              }}
            >
              <EditOutlined className="me-0 me-lg-2" />
              <span className="d-none d-lg-block"> {t('label:btn.edit')}</span>
            </CommonButton>
          </Row>
        );
      },
      key: 'action',
      align: 'left',
      width: 120,
    },
  ];

  const onSearchMemberHandle = async (phone: string) => {
    try {
      const callApi = await getMemberLinkServices(phone);
      setMember(callApi.data);
    } catch (err) {
      setMember(null);
      setHasError(true);
      setMessageError((err as any)?.response?.data?.detail);
    }
  };

  const handlerSuccessUpdateMember = () => {
    if (phoneSearch) {
      onSearchMemberHandle(phoneSearch);
    }
  };

  const hanlderCancelUpdateMember = () => {
    setOpenUpdateMember(false);
  };

  const providerSettingCatchError = (message: string) => {
    setHasError(true);
    setMessageError(message);
  };

  // const navigateToBanner = () => {
  //   history.push(AppRoutes.bannerSetting);
  // };

  return (
    <>
      <DashboardLayout>
        <div className="dashboard-center-width m-auto h-100 background-admin my-5 p-4">
          <Col span={24} className="admin-card-cpn pt-2 pb-5 px-4">
            <Row>
              <h2 className="my-3 text-orange">{t('label:admin.access')}</h2>
            </Row>
            <Row className="d-flex flex-row">
              <Col span={16} md={8} lg={8}>
                <Search
                  placeholder={t('label:user.search_by_phone')}
                  size="large"
                  onSearch={(phone) => {
                    setPhoneSearch(phone);
                  }}
                />
              </Col>
              <Row className="ms-auto d-flex align-items-center">
                {/* <CommonButton className="btn-setting me-3" hidedefaultpadding={true} onClick={navigateToBanner}>
                  <FileImageOutlined className="me-0 me-lg-2" />
                  <span className="d-none d-lg-block">{t('label:admin.banner_setting')}</span>
                </CommonButton> */}

                <CommonButton
                  className="btn-setting"
                  hidedefaultpadding={true}
                  onClick={() => setProviderSetting(true)}
                >
                  <SettingOutlined className="me-0 me-lg-2" />
                  <span className="d-none d-lg-block">{t('label:admin.provider_setting')}</span>
                </CommonButton>
              </Row>
            </Row>
            {member && (
              <Row>
                <Table
                  scroll={{ x: 'auto' }}
                  columns={columns}
                  dataSource={[member]}
                  rowKey={(row) => row?.id}
                  className="table-admin-access mt-3 w-100"
                  rowClassName=" text-white"
                  pagination={false}
                />
              </Row>
            )}
          </Col>
        </div>
      </DashboardLayout>

      <ProviderSetting
        onError={providerSettingCatchError}
        open={providerSetting}
        onCancel={() => setProviderSetting(false)}
      />
      <UpdateMemberPopup
        open={openUpdateMember}
        member={member}
        onCancelModal={hanlderCancelUpdateMember}
        onSuccessUpdate={handlerSuccessUpdateMember}
      />
      <ErrorPopup messageError={messageError?.toUpperCase()} open={hasError} onCancel={() => setHasError(false)} />
    </>
  );
}

export default AdminPage;
