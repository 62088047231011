import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

import telegramIcon from 'src/assets/images/telegram.svg';
import ArrowDownIcon from 'src/assets/images/arrow-down.svg';
import ArrowUpIcon from 'src/assets/images/arrow-up.svg';

import { TelegramCommunity, FLAG_ICON_SIZE } from 'src/helpers/constanst';

const TelegramInfo = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Dropdown show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle variant="secondary" className="d-flex align-items-center" id="dropdownMenuTelegram">
        <div>
          <img className="telegram-icon ms-0 me-2" src={telegramIcon} alt="telegram icon" />
          <label className="fz-connect-us">{t('label:connect_us_title')}</label>
        </div>

        <span className={`dropdown-arrow ${isOpen ? 'up' : 'down'}`}>
          {isOpen ? (
            <img className="telegram-icon ms-3" src={ArrowUpIcon} alt="arrow up icon" />
          ) : (
            <img className="telegram-icon ms-3" src={ArrowDownIcon} alt="arrow down icon" />
          )}
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="w-100 mt-1" id="menuTelegram">
        {TelegramCommunity.map((item) => (
          <Dropdown.Item className="cursor-pointer" href={item.link} target="_blank" key={item.id}>
            <img className="me-1" width={FLAG_ICON_SIZE} height={FLAG_ICON_SIZE} src={item.flag} alt="" />
            <label className="cursor-pointer ms-2">{item.id}</label>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TelegramInfo;
